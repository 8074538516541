import * as React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from 'react-router-dom';
import useSWR from 'swr';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Div } from '@danfoss/etui-system-elements';
import { Unit, Device, ConfigurationTabItem } from '@danfoss/etui-sm-xml';
import { PageNotFound, useXmlResource, useAuth } from '@danfoss/etui-sm';
import { areObjectsEqual } from 'utils';
import {
  ConfigurationTabByUnitResponse,
  fetchConfigurationTabByUnit,
} from '../../actions';
import {
  ConfigurationCustomActionsProvider,
  useConfiguration,
} from '../../context';
import { ConfigurationItemContentList } from '../ConfigurationItemContentList';
import { ConfigurationItemContentMenu } from '../ConfigurationItemContentMenu';
import { useConfigDevices } from '../../hooks';
import { OLD_CONFIG_TYPE, TABLE_ADDRESS } from '../../utils';
import {
  findCurrentMenuItemWithLevel,
  findMenuItemByMenuId,
  getTabByRootMenuIdOrMenuId,
} from './utils';

interface ConfigurationItemContentProps {
  unit: Unit;
  level: number;
  InfoElement?: any;
  onPasswordUpdatedForCurrentUser: (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => void;
  updateTime?: (menuId: string, currentTimeMs: number) => void;
  deviceFromTopLevel?: Device;
}

const ConfigurationItemContent = React.memo(
  ({
    unit,
    level,
    InfoElement,
    onPasswordUpdatedForCurrentUser,
    updateTime,
    deviceFromTopLevel = null,
  }: ConfigurationItemContentProps) => {
    const {
      push,
      location: { pathname, state },
    } = useHistory();

    const { url } = useRouteMatch();
    const { menuId } = useParams<{ menuId: string }>();
    const { user } = useAuth();
    const { url: xmlBackendURL } = useXmlResource();

    const {
      route: baseRoute,
      menuLevelById,
      rootMenuId,
      itemByTabRenderers,
      menuItemsById,
      subMenuItemsById,
      onSetMenuItems,
      onSetSubMenuItems,
      onSetMenuLevel,
      cachedDevices,
    } = useConfiguration();

    const currentMenuId: string = pathname.split('/').filter(Boolean).pop();
    const currentTab: ConfigurationTabItem = findMenuItemByMenuId(
      menuItemsById,
      currentMenuId,
    );

    const [device, setDevice] = React.useState<Device>(
      // @ts-ignore
      deviceFromTopLevel || state?.device,
    );
    const [deviceGroup, setDeviceGroup] = React.useState('0');
    const [deviceSubgroup, setDeviceSubgroup] = React.useState('0');
    const [page, setPage] = React.useState('0');

    const [tabId, tabOrder] = menuId?.split('-');
    const thisTabItemRenderer =
      itemByTabRenderers[tabOrder ? `${tabId}-${tabOrder}` : tabId];

    const isLastInRecursion =
      pathname?.lastIndexOf(menuId) === pathname?.length - menuId?.length;

    /**
     * start
     *
     * This basically done for request attribiutes:
     * old_cfgtype as secondLevelMenuItem.configuretype
     * and
     * configuretype as closestParentTab.configuretype
     * which are needed for correct request of tabs and list items.
     */

    const topLevelMenuId = findCurrentMenuItemWithLevel(
      menuLevelById,
      pathname,
      0,
    );

    const closestParentMenuId = findCurrentMenuItemWithLevel(
      menuLevelById,
      pathname,
      level - 1,
    );

    const secondLevelMenuItem = menuItemsById[topLevelMenuId]?.find(({ id }) =>
      new RegExp(`/${id}`).test(pathname),
    );

    const closestParentTab = menuItemsById[closestParentMenuId]?.find(
      ({ id }) => id === menuId,
    );

    /**
     * end
     */

    const tab = React.useMemo(() => {
      return (
        getTabByRootMenuIdOrMenuId(
          baseRoute,
          rootMenuId,
          menuId,
          pathname,
          menuItemsById,
          subMenuItemsById,
        ) || subMenuItemsById[menuId]?.find(submenu => submenu.id === menuId)
      );
    }, [
      baseRoute,
      rootMenuId,
      menuId,
      pathname,
      menuItemsById,
      subMenuItemsById,
    ]);

    const isMcxAddressTab = pathname.includes(TABLE_ADDRESS.MCX);
    const basicRequest = [
      xmlBackendURL,
      unit,
      menuId,
      user,
      cachedDevices,
      false, // skipSessionUpdate
      '0',
      '1',
      device ? parseInt(device.bpidx, 10) - 1 : '0',
      device ? device.stype : null,
      device ? device.nodetype : null,
      device ? device.node : null,
      deviceGroup || '0',
      deviceSubgroup || '0',
      page,
      currentTab
        ? currentTab.combo
        : tab
        ? tab.combo
        : device
        ? device.combo
        : '0',
      closestParentTab
        ? closestParentTab.configuretype
        : tab
        ? tab.configuretype
        : null,
      device ? device.arg1 : null,
      device ? device.arg2 : null,
      device ? device.arg3 : null,
      device ? device.online : null,
    ];

    const isInfoPage = !!InfoElement;

    const { data, error, mutate } = useSWR<ConfigurationTabByUnitResponse>(
      !unit || isInfoPage || !tabId
        ? null
        : level === 0
        ? basicRequest
        : isMcxAddressTab
        ? [...basicRequest, OLD_CONFIG_TYPE.MCX]
        : secondLevelMenuItem?.configuretype
        ? [...basicRequest, secondLevelMenuItem.configuretype]
        : null,
      fetchConfigurationTabByUnit,
      {
        revalidateOnFocus: false,
        shouldRetryOnError: true,
      },
    );

    const tabs = data?.tabs || [];
    const subtabs = data?.subtabs || [];
    const list = data?.list;
    const groupnames = data?.groupnames || [];
    const subgroupnames = data?.subgroupnames || [];
    const multipage = data?.multipage || '0';

    React.useEffect(() => {
      if (data?.multipage && +page >= +multipage) setPage('0');
    }, [multipage]);

    useDeepCompareEffect(() => {
      if (subMenuItemsById[menuId] && !tab) return;

      onSetMenuLevel(menuId, level);
      onSetMenuItems(menuId, tabs);

      if (subtabs.length && !subtabs.map(({ id }) => id).includes(menuId)) {
        const newTab =
          subtabs.find(({ label }) => label === tab?.label) || subtabs[0];
        const menuPath = pathname.split('/').slice(0, -1).join('/');
        onSetSubMenuItems(newTab.id, subtabs);
        push(`${menuPath}/${newTab.id}`, { device });
      } else {
        onSetSubMenuItems(menuId, subtabs);
      }
    }, [menuId, subtabs, tabs]);

    const handlePasswordUpdatedForCurrentUser = (
      passwordUpdated: boolean,
      updatedPassword: string,
    ) => {
      onPasswordUpdatedForCurrentUser(passwordUpdated, updatedPassword);
    };

    const isFailed = !isInfoPage && !list && error;

    const { devices, isLoading: isDevicesLoading } = useConfigDevices({
      unit,
      combo: tab?.combo,
      stype: tab?.stype,
    });

    React.useEffect(() => {
      if (
        !isDevicesLoading &&
        !devices?.some(d => areObjectsEqual(d, device))
      ) {
        setDevice(null);
      }
      setDeviceGroup('0');
      setDeviceSubgroup('0');
      setPage('0');
    }, [menuId, unit, isDevicesLoading]);

    if (menuId && !tabId) return <PageNotFound />;

    return (
      <>
        <Switch>
          <Route path={`${url}/:menuId`}>
            <ConfigurationItemContent
              unit={unit}
              InfoElement={InfoElement}
              level={level + 1}
              onPasswordUpdatedForCurrentUser={
                handlePasswordUpdatedForCurrentUser
              }
              deviceFromTopLevel={device}
            />
          </Route>
        </Switch>
        {/*  we have no error component for not Info Page */}
        {isFailed && isInfoPage ? (
          <Div>
            <InfoElement selectedUnit={unit} />
          </Div>
        ) : tab && isLastInRecursion ? (
          <Div
            height="100%"
            display="flex"
            flexDirection="column"
            testId="config-item-content"
          >
            <ConfigurationItemContentMenu
              tab={tab}
              unit={unit}
              menuId={menuId}
              groupnames={groupnames}
              subgroupnames={subgroupnames}
              multipage={multipage}
              device={device}
              setDevice={setDevice}
              deviceGroup={deviceGroup}
              setDeviceGroup={setDeviceGroup}
              deviceSubgroup={deviceSubgroup}
              setDeviceSubgroup={setDeviceSubgroup}
              page={page}
              setPage={setPage}
            />
            <Div id="config-item-content-list" flex={1} overflowY="auto">
              <ConfigurationCustomActionsProvider>
                <ConfigurationItemContentList
                  menuId={menuId}
                  unit={unit}
                  tab={tab}
                  isconfigure="1"
                  itemRenderer={thisTabItemRenderer}
                  secondLevelMenuItems={secondLevelMenuItem}
                  onPasswordUpdatedForCurrentUser={
                    handlePasswordUpdatedForCurrentUser
                  }
                  device={device}
                  deviceGroup={deviceGroup}
                  deviceSubgroup={deviceSubgroup}
                  page={page}
                  updateTime={updateTime}
                  updatePageData={mutate}
                />
              </ConfigurationCustomActionsProvider>
            </Div>
          </Div>
        ) : null}
      </>
    );
  },
);

export { ConfigurationItemContent };
