import * as React from 'react';
import { darken, lighten } from '@danfoss/etui-colors';
import { Button, EmptyState, IconGlyph, icons } from '@danfoss/etui-core';
import { useTheme, StyleProps } from '@danfoss/etui-system';
import { Input } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';

export const EmptyPageContent = React.memo(
  ({
    title,
    description,
    glyph,
    style,
    showImportButton = false,
    handleBrowse,
  }: {
    title: string;
    description?: string | React.ReactNode;
    glyph: IconGlyph;
    style?: {
      icon: StyleProps;
    };
    showImportButton?: boolean;
    handleBrowse?: (file: File) => void;
  }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const inputRef = React.useRef(null);
    const openBrowseWindow = () => inputRef.current.click();

    return (
      <EmptyState
        testId="emptyPage-content-emptyState"
        styles={{
          root: {
            height: `100%`,
            color: theme.palette.grey[40],
            bg: darken(theme.palette.common.bg, 0.01),
          },
        }}
      >
        <EmptyState.Icon
          // @ts-ignore
          size={256}
          glyph={glyph}
          styles={{
            root: {
              mt: style?.icon ? style.icon.mt : -112,
              color: lighten(theme.palette.grey[40] as string, 0.25),
            },
          }}
        />
        <EmptyState.Title color={theme.palette.grey[60]}>
          {title}
        </EmptyState.Title>
        {description && (
          <EmptyState.SubTitle color={theme.palette.grey[60]}>
            {description}
          </EmptyState.SubTitle>
        )}
        {showImportButton && (
          <>
            <Input
              type="file"
              hidden={true}
              accept=".hst"
              multiple={false}
              onChange={e => {
                const file = e ? e.target.files[0] : null;
                handleBrowse(file);
              }}
              elRef={inputRef}
            />
            <Button
              variant="primary"
              small={true}
              height={35}
              mt={3}
              width="110%"
              iconProps={{
                glyph: icons.DOWNLOAD,
                styles: { root: { mr: '5px' } },
              }}
              onClick={openBrowseWindow}
            >
              {t('t3846')}
            </Button>
          </>
        )}
      </EmptyState>
    );
  },
);
