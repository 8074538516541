import * as React from 'react';
import { Button, Notification } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { XML_REFRIG_LAYOUT_TAB_INDEX } from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { useModal } from '@danfoss/etui-sm';
import { WizardConfirmationModal } from '../WizardConfirmationModal';
import { useRefrigLayout } from './context';
import { DEVICE_TYPE } from './types/DiscoveryPage.types';
import { RefrigLayoutFinishModal } from './components/SuctionGroupsFlowContent/RefrigLayoutFinishModal';

export function RefrigLayoutModalActions({
  onCancel,
  onCompleteRefrigLayout,
}: {
  onCancel: () => void;
  onCompleteRefrigLayout: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    step,
    setStep,
    activeStepNumber,
    setActiveStepNumber,
    refrigSteps,
    setDiscoveryTableData,
    setDeviceCompressorData,
    discoveryTableData,
    deviceCompressorData,
    setFilteredTableData,
    setIsOfflineProgramming,
    compressorControlData,
    setCompressorControlData,
    setAvailableCasesData,
    setSearchedAvailableCasesData,
    unit,
  } = useRefrigLayout();

  const handleOnOkClick = () => {
    setDefault();
    setStepValues();
  };

  const setDefault = () => {
    setDiscoveryTableData([]);
    setDeviceCompressorData(null);
    setFilteredTableData([]);
    setCompressorControlData([]);
    setAvailableCasesData([]);
    setSearchedAvailableCasesData([]);
    setIsOfflineProgramming(true);
  };

  const [showWizardConfirmationModal] = useModal(WizardConfirmationModal, {
    handleOnOkClick,
    title: t('t596'),
    text: t('t3379'),
    subText: t('t3380'),
  });

  const handleOnBack = () => {
    if (step === +XML_REFRIG_LAYOUT_TAB_INDEX.COMPRESSORS) {
      showWizardConfirmationModal();
      return;
    }
    if (step === +XML_REFRIG_LAYOUT_TAB_INDEX.DISCOVERY) {
      setDefault();
    }
    setStepValues();
  };

  const setStepValues = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep -= 1;
    currentStepNumber -= 1;

    if (currentStep < +XML_REFRIG_LAYOUT_TAB_INDEX.NETWORK) {
      onCancel();
    }
    if (
      currentStep < +XML_REFRIG_LAYOUT_TAB_INDEX.SUMMARY &&
      currentStep >= +XML_REFRIG_LAYOUT_TAB_INDEX.NETWORK
    ) {
      if (currentStep === +XML_REFRIG_LAYOUT_TAB_INDEX.PI200) {
        currentStep -= 1;
      }
      setStep(currentStep);
    }
    if (currentStepNumber >= refrigSteps[0]?.stepNumber) {
      setActiveStepNumber(currentStepNumber);
    }
  };

  const handleOnFinish = () => {
    onCompleteRefrigLayout();
  };

  const [showRefrigLayoutFinishModal] = useModal(RefrigLayoutFinishModal, {
    refrigLayoutFinalTableData: discoveryTableData,
    compressorControlData,
    handleOnFinish,
    unit,
  });

  const handleOnNextButton = () => {
    let currentStep = step;
    let currentStepNumber = activeStepNumber;
    currentStep += 1;
    currentStepNumber += 1;
    if (step === +XML_REFRIG_LAYOUT_TAB_INDEX.COMPRESSORS) {
      if (
        !discoveryTableData?.length ||
        !deviceCompressorData?.suction?.length
      ) {
        Notification.error({
          message: t('t17'),
          description: t('t790'),
          duration: 3,
          theme,
          testId: 'refrigiration-compressor-notification',
        });
        return;
      }
      if (deviceCompressorData?.suction?.length) {
        const isDeviceFound = !!discoveryTableData?.filter(
          device =>
            (device?.deviceType === DEVICE_TYPE.GEN_PACK ||
              device?.deviceType === DEVICE_TYPE.RACK ||
              device.deviceType === DEVICE_TYPE.NO_COMP) &&
            device?.num_sg === 0,
        ).length;
        if (isDeviceFound) {
          Notification.error({
            message: t('t17'),
            description: t('t3363'),
            duration: 3,
            theme,
            testId: 'refrigiration-deviceCompressor-notification',
          });
          return;
        }
      }
    }
    if (step === +XML_REFRIG_LAYOUT_TAB_INDEX.SUCTION_GROUPS) {
      if (!compressorControlData?.length) {
        Notification.error({
          message: t('t17'),
          description: t('t3381'),
          duration: 3,
          theme,
          testId: 'refrigiration-suctionGroup-notification',
        });
        return;
      }
      showRefrigLayoutFinishModal();
    }
    if (
      currentStep < +XML_REFRIG_LAYOUT_TAB_INDEX.SUMMARY &&
      currentStep >= +XML_REFRIG_LAYOUT_TAB_INDEX.NETWORK
    ) {
      if (currentStep === +XML_REFRIG_LAYOUT_TAB_INDEX.PI200) {
        currentStep += 1;
      }
      setStep(currentStep);
    }
    if (currentStepNumber <= refrigSteps[refrigSteps.length - 1]?.stepNumber) {
      setActiveStepNumber(currentStepNumber);
    }
  };
  return (
    <Div float="right">
      <Button
        testId="refrig-layout-back-button"
        width="150px"
        onClick={handleOnBack}
        variant="secondary"
        styles={{ root: { mr: 10 } }}
      >
        {step === +XML_REFRIG_LAYOUT_TAB_INDEX.NETWORK ? t('t45') : t('t710')}
      </Button>
      <Button
        width="150px"
        variant="primary"
        styles={{ root: { mr: 10 } }}
        testId="refrig-layout-modalNext-button"
        onClick={handleOnNextButton}
      >
        {step === +XML_REFRIG_LAYOUT_TAB_INDEX.SUCTION_GROUPS
          ? t('t430')
          : t('t118')}
      </Button>
    </Div>
  );
}
