import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { darken, lighten } from '@danfoss/etui-colors';
import { EmptyState, icons } from '@danfoss/etui-core';

function UnitConfigurationBaseStaleState() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <EmptyState
      testId="unitConfig-baseStale-emptySate"
      styles={{
        root: {
          height: `100%`,
          color: theme.palette.grey[40],
          bg: darken(theme.palette.common.bg, 0.01),
        },
      }}
    >
      <EmptyState.Icon
        // @ts-ignore
        size={256}
        glyph={icons.COG}
        styles={{
          root: {
            mt: -112,
            color: lighten(theme.palette.grey[40], 0.25),
          },
        }}
      />
      <EmptyState.Title color={theme.palette.grey[60]}>
        {t('t156')}
      </EmptyState.Title>
      <EmptyState.SubTitle color={theme.palette.grey[60]}>
        {t('t962')}
      </EmptyState.SubTitle>
    </EmptyState>
  );
}

export { UnitConfigurationBaseStaleState };
