import * as React from 'react';
import { EmptyState, icons } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { ContentSpinner } from '@danfoss/etui-sm';
import * as S from './styles';
import { HelpPanelCategoryItem } from './HelpPanel.types';
import { InfoItemCategory } from './InfoItemCategory';

export interface InfoItemCategoryContainerProps {
  items: HelpPanelCategoryItem[];
  categoryIconMapping?: any;
  isLoading?: boolean;
  isError?: boolean;
}

const InfoItemCategoryContainer: React.FC<InfoItemCategoryContainerProps> = ({
  items,
  categoryIconMapping,
  isLoading,
  isError,
}) => {
  const theme = useTheme();
  if (isLoading) return <ContentSpinner transparent={true} />;

  if (isError || items?.length === 0)
    return (
      <EmptyState testId="infoItem-category-emptyState">
        <EmptyState.Icon
          size={96}
          glyph={isError ? icons.ERROR_CIRCLE : icons.HELP_CIRCLE}
          styles={{
            root: {
              color: isError
                ? theme.palette.error.main
                : theme.palette.action.disabled,
            },
          }}
        />
        <EmptyState.SubTitle
          color={isError ? 'initial' : theme.palette.action.disabled}
        ></EmptyState.SubTitle>
      </EmptyState>
    );

  return (
    <S.InfoItemsScrollWrapper>
      {items
        .sort((first, second) =>
          first.categoryName?.localeCompare(second.categoryName),
        )
        .map(item => (
          <InfoItemCategory
            key={item.categoryKey}
            category={item}
            categoryIconMapping={categoryIconMapping}
          />
        ))}
    </S.InfoItemsScrollWrapper>
  );
};

export default InfoItemCategoryContainer;
