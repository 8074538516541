import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TableProps } from '@danfoss/etui-core';
import { Device } from '@danfoss/etui-sm-xml';
import { A } from '@danfoss/etui-system-elements';
import { DeviceType } from '@danfoss/etui-sm/types';
import { ContentEmpty } from '@danfoss/etui-sm/components';
import { TableVirtualized } from 'components/TableVirtualized';
import { Link, useLocation } from 'react-router-dom';
import { DeviceInfoColumn } from './DeviceInfoColumn';
import { ValueColumn } from './ValueColumn';

const EquipmentPath = '/equipment';

interface EquipmentTableProps {
  deviceType: DeviceType;
  devices: Device[];
  height: number;
  rowHeight?: number;
  getDeviceRoute: (device: Device) => string;
}

function EquipmentTable({
  deviceType,
  devices,
  height,
  rowHeight = 42,
  getDeviceRoute,
}: EquipmentTableProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isEquipmentPage = pathname === EquipmentPath;

  const textId =
    deviceType === 'refrig'
      ? 't150'
      : deviceType === 'hvac'
      ? 't87'
      : deviceType === 'lights'
      ? 't109'
      : deviceType === 'meter'
      ? 't374'
      : 't212';

  const deviceTypeText = t(textId);

  function StyledRouterLink(props) {
    return <Link component={A} {...props} />;
  }

  const isRefrigDevice = deviceType === 'refrig';
  const standardColumnWitdth = isRefrigDevice ? '25%' : '28%';
  const deviceNameColumnWidth = isRefrigDevice ? '30%' : '45%';

  const columns: TableProps<Device>['columns'] = [
    {
      title: t('t76'),
      dataIndex: 'name',
      width: deviceNameColumnWidth,
      ellipsis: true,
      render: (value, record: Device) => {
        return <DeviceInfoColumn {...record} name={value} />;
      },
    },
    {
      title: t('t77'),
      dataIndex: 'value',
      width: standardColumnWitdth,
      ellipsis: true,
      render: value => <ValueColumn value={value} width="auto" />,
    },
    {
      title: t('t42'),
      dataIndex: 'status',
      width: standardColumnWitdth,
      ellipsis: true,
      columnBreakpoints: ['sm', 'md', 'lg'],
      render: (value, record) => (
        <ValueColumn
          value={value}
          width="auto"
          isDefrosting={record?.defrost === '1'}
          hasAlarm={record?.alarm === '1'}
        />
      ),
    },
  ];

  if (isRefrigDevice && isEquipmentPage) {
    columns.push({
      title: t('t161'),
      dataIndex: 'ctrl_val',
      width: '25%',
      ellipsis: true,
      render: value => <ValueColumn value={value} width="auto" />,
    });
  }

  return (
    <>
      {devices.length === 0 ? (
        <ContentEmpty
          title={t('t960')}
          message={t('t961', { type: deviceTypeText })}
        />
      ) : (
        <TableVirtualized
          testId="equipmentTable-tableVirtualized"
          key={deviceType}
          rowKey={(record: Device, index) => `${record.name}-${index}`}
          rowAs={StyledRouterLink}
          rowPropsGetter={(record: Device) => ({
            to: getDeviceRoute(record),
          })}
          rowHeight={rowHeight}
          columns={columns}
          dataSource={devices}
          pagination={false}
          emptyPlaceholder={null}
          scroll={{ y: height }}
        />
      )}
    </>
  );
}

export { EquipmentTable };
