import { isXmlError } from '@danfoss/etui-sm-xml';
import '../../../../../constants/software-update-type.js';
import { Notification } from '@danfoss/etui-core';
import '../../../../../constants/session-storage-constants.js';
import '../../../../../constants/software-versions.js';
import '../../../../../constants/software-update-unit-status.js';
import { SESSION_EXPIRY_CODE } from '../../../../../constants/index.js';
import '../../../../../actions/authorize-units.js';
import '../../../../../_rollupPluginBabelHelpers-e1ae8e90.js';
import '../../../../../actions/fetch-initial-unit.js';
import '../../../../../actions/fetch-metadata-files.js';
import '../../../../../actions/fetch-other-units.js';
import '../../../../../utils/get-measurements-by-unit.js';
import '../../../../../utils/get-software-version-prefix.js';
import '../../../../../utils/get-is-matched-software-version.js';
import '../../../../../utils/get-is-misc-device.js';
import '../../../../../utils/get-language-code-by-name.js';
import '../../../../../utils/get-user-from-storage.js';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'swr';
import { useTheme } from '@danfoss/etui-system';
import '../../../../../context/modal/modal-context.js';
import '../../../../../context/modal/use-modal.js';
import 'use-deep-compare-effect';
import '../../../../../context/app/utils/get-xml-backend-ip.js';
import '../../../../../context/app/utils/get-xml-backend-protocol.js';
import '../../../../../context/app/utils/get-xml-proxy-config.js';
import '../../../../../context/app/utils/set-xml-proxy-config.js';
import '../../../../../context/app/app-context.js';
import '../../../../../context/app/hooks/use-app.js';
import '../../../../../context/app/hooks/use-xml-resource.js';
import '@danfoss/etui-sm-i18n';
import '../../../../../context/auth/utils/logout-clean-up.js';
import '../../../../../context/auth/utils/set-user-to-storage.js';
import '../../../../../context/auth/auth-context.js';
import { useAuth } from '../../../../../context/auth/hooks/use-auth.js';
import 'styled-components';
import '../../../../../context/unit/utils/duplicate-session-info.js';
import '../../../../../context/unit/unit-actions.js';
import '../../../../../context/unit/unit-context.js';
import 'lodash.isequal';
import 'react-device-detect';
import 'featurehub-javascript-client-sdk';
import { readFileAsync } from '../utils/read-file-async.js';
import { fetchUploadFile } from '../actions/fetch-upload-file.js';

const MAX_FILE_CHUNK_SIZE = 2457600;
function useXmlFileUpload(url, user, file, isDBUpdateFlow, startUpload, isS55ToSM800A) {
  const theme = useTheme();
  const {
    t
  } = useTranslation();
  const {
    dispatch
  } = useAuth();
  const [progress, setProgress] = useState(0);
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const handleSetProgress = (index, fileChunks) => {
    const totalChunks = fileChunks.length;
    setProgress(Math.min(Math.ceil(index * 100 / totalChunks), 100));
  };
  useEffect(() => {
    if (!startUpload) {
      return;
    }
    const uploadFile = async () => {
      setPending(true);
      const done = 0;
      const index = 1;
      const base64 = await readFileAsync(file);
      const b64 = base64.split('base64,').pop();
      const fileChunksArray = [];
      const fileSize = file.size;
      const fileName = file.name;
      let b64FileSliced = b64;
      const fileLength = b64FileSliced.length;

      // If file length is greater than 2457600 divide it into pieces and store it into an array.
      if (fileLength > MAX_FILE_CHUNK_SIZE) {
        // MAX file size allowed is 128MB, RMT used 102400.
        const numFileChunks = Math.ceil(fileLength / MAX_FILE_CHUNK_SIZE);
        let fileChunk;
        for (let i = 0; i < numFileChunks; i++) {
          // Last file chunk will be the remaining bites.
          fileChunk = b64FileSliced.substr(0, MAX_FILE_CHUNK_SIZE);
          fileChunksArray.push(fileChunk);
          b64FileSliced = b64FileSliced.slice(MAX_FILE_CHUNK_SIZE);
        }
      } else {
        fileChunksArray.push(b64FileSliced);
      }
      try {
        const response = await fetchUploadFile(url, user, fileChunksArray, fileName, fileSize, done, index, null, handleSetProgress, isDBUpdateFlow, isS55ToSM800A);
        setPending(false);
        setValue(response);
      } catch (e) {
        if (isXmlError(e) && +e.code === SESSION_EXPIRY_CODE) {
          Notification.error({
            theme,
            message: t('t2100'),
            description: '',
            duration: 5,
            testId: 'useXml-sessionExpire-notification'
          });
          dispatch({
            type: 'LOGOUT_USER'
          });
        }
        setPending(false);
        setError(e);
      }
    };
    uploadFile();
  }, [startUpload]);
  return {
    progress,
    pending,
    value,
    error
  };
}

export { useXmlFileUpload };
