import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { darken } from '@danfoss/etui-colors';
import { Button, Tooltip } from '@danfoss/etui-core';
import { Unloader } from '@danfoss/etui-icons';
import { useTranslation } from 'react-i18next';

export interface ConfigurationListItemEditStagingPatternUnloadersProps {
  visibilities: boolean[];
  statuses: boolean[];
  rowIndex: number;
  compIndex: number;
  handleUnloaderClick: (
    rowIndex: number,
    compIndex: number,
    unloaderIndex: number,
  ) => void;
}

export const ConfigurationListItemEditStagingPatternUnloaders = ({
  visibilities,
  statuses,
  rowIndex,
  compIndex,
  handleUnloaderClick,
}: ConfigurationListItemEditStagingPatternUnloadersProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {visibilities
        .map((visibility, unloaderIndex) => {
          return (
            <Div
              key={unloaderIndex}
              testId={`unloaderIcon-${unloaderIndex}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              width="20px"
              height="20px"
              border={`2px solid ${
                visibility === statuses[unloaderIndex]
                  ? theme.palette.success.main
                  : theme.palette.action.disabled
              }`}
              bg={darken(theme.palette.common.bg, 0.02)}
              color={
                visibility === statuses[unloaderIndex]
                  ? theme.palette.success.main
                  : theme.palette.action.disabled
              }
            >
              <Tooltip
                testId={`unloaderButton-${unloaderIndex}-tooltip`}
                placement="bottom"
                message={t('t3791')}
                styles={{
                  div: { lineHeight: 0 },
                }}
              >
                <Button
                  testId={`unloaderButton-${unloaderIndex}`}
                  onClick={() => {
                    handleUnloaderClick(rowIndex, compIndex, unloaderIndex);
                  }}
                >
                  <Unloader fontSize={14} />
                </Button>
              </Tooltip>
            </Div>
          );
        })
        .reverse()}
    </>
  );
};
