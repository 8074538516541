import * as React from 'react';
import { Modal, useResponsive } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { P } from '@danfoss/etui-system-elements';
import { useKeyboardEvent } from 'hooks/use-keyboardevent';

export const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });

  const isMd = screenIsAtMost('md', ['portrait', 'landscape']);

  useKeyboardEvent('Enter', onConfirm);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      testId="login-confirmation-modal"
      header={
        <Modal.Header
          testId="login-confirmation-modal-header"
          title={t('t3499')}
        />
      }
      actionButtons={[
        {
          variant: 'tertiary',
          onClick: onClose,
          children: t('t45'),
        },
        {
          variant: 'primary',
          onClick: onConfirm,
          children: t('t2215'),
        },
      ]}
      style={{
        content: {
          margin: isMd && theme.spacing.md,
        },
      }}
    >
      <P>{t('t3519')}</P>
      <P>{t('t186')}</P>
    </Modal>
  );
};
