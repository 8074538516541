import React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Div, Span } from '@danfoss/etui-system-elements';
import { SingleValueProps } from 'react-select';

export interface SingleValuePropsData {
  value: string;
  label: string;
  displayName?: string;
  addr?: string;
}

interface StyledSelectProps extends SingleValueProps<SingleValuePropsData> {}

export const StyledSelect: React.FC<StyledSelectProps> = React.memo(
  ({ data: { displayName, addr } }) => {
    const theme = useTheme();
    return (
      <Div
        testId="unit-selector-div"
        style={{
          cursor: 'pointer',
        }}
      >
        <Span
          style={{
            fontWeight: displayName
              ? theme.typography.fontWeightExtraBold
              : theme.typography.fontWeightRegular,
          }}
        >
          {displayName}
        </Span>
        {addr && (
          <Span style={{ fontWeight: theme.typography.fontWeightRegular }}>
            {` addr: ${addr}`}
          </Span>
        )}
      </Div>
    );
  },
);
