import * as React from 'react';
import { Modal, useResponsive } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { P } from '@danfoss/etui-system-elements';
import { AddressBook } from 'types';
import { getAddressBookObject } from 'utils/addressbook-helpers';
import { showSuccessNotification } from 'pages/LoginPage/utils';
import { downloadFile } from './utils/downloadFile';

export const ExportModal = ({ isOpen, onClose, addressBookPassword }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMd = screenIsAtMost('md', ['portrait', 'landscape']);

  const exportAddressBook = () => {
    const addressBookObject: AddressBook =
      getAddressBookObject(addressBookPassword);
    exportFile(addressBookObject);
    finishExport();
  };

  const exportFile = (addressBook: AddressBook) => {
    const content = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(addressBook),
    )}`;
    downloadFile(content);
  };

  const finishExport = () => {
    showSuccessNotification(theme, t, t('t3651'));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      testId="login-export-modal"
      header={
        <Modal.Header testId="login-export-modal-header" title={t('t3463')} />
      }
      actionButtons={[
        {
          testId: 'cancel-addressbook-passwords-export',
          variant: 'primary',
          onClick: onClose,
          children: t('t45'),
        },
        {
          testId: 'export-addressbook-passwords-btn',
          variant: 'primary',
          onClick: exportAddressBook,
          children: t('t2214'),
        },
      ]}
      style={{
        content: {
          margin: isMd && theme.spacing.md,
        },
      }}
    >
      <P>{t('t3503')}</P>
    </Modal>
  );
};
