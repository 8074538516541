import { Modal } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Div, P } from '@danfoss/etui-system-elements';
import { useKeyboardEvent } from 'hooks/use-keyboardevent';
import { useTranslation } from 'react-i18next';
import { PollingSuspensionModalProps } from '../types';

export const PollingSuspensionModal = ({
  isOpen,
  onClose,
  onConfirm,
}: PollingSuspensionModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useKeyboardEvent('Enter', onConfirm);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      testId="polling-suspension-modal"
      header={
        <Modal.Header
          testId="polling-suspension-modal-header"
          title={t('t3778')}
        />
      }
      actionButtons={[
        {
          variant: 'secondary',
          onClick: onClose,
          children: t('t299'),
        },
        {
          variant: 'primary',
          onClick: onConfirm,
          children: t('t298'),
        },
      ]}
      styles={{
        contentContainer: {
          width: '500px',
          height: '110px',
        },
      }}
      shouldCloseOnOverlayClick={false}
    >
      <Div m={'5px 5px'} textAlign={'center'}>
        <P fontSize={theme.typography.fontSizeLarge}>{t('t3779')}</P>
        <P fontSize={theme.typography.fontSizeLarge}>{t('t3780')}</P>
      </Div>
    </Modal>
  );
};
