import React from 'react';
import { icons, ellipsis, Icon } from '@danfoss/etui-core';
import { Div, DivProps } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { DeviceGroupedType } from '@danfoss/etui-sm';
import * as S from './styles';

export function EquipmentTreeviewSelectItem({ options, item, level = 0 }) {
  const theme = useTheme();

  const { isRowSelected, openSections, getToggleProps, getItemRowProps } =
    options;

  const { onClick, onKeyDown } = getItemRowProps();
  const { onClick: onToggle, ...toggleProps } = getToggleProps();
  const hasNestedItems = !!item.items?.length;
  const isOpen = openSections.includes(item.id);

  const renderSubitems = () => {
    if (!hasNestedItems || !isOpen) return null;

    return item.items.map(subitem => (
      <EquipmentTreeviewSelectItem
        key={subitem.id}
        options={options}
        item={subitem}
        level={level + 1}
      />
    ));
  };
  return (
    <Div flex={1}>
      <S.TreeViewItemContentDiv
        testId="equipmentTreeSelect-treeViewContentDiv"
        isRowSelected={isRowSelected(item)}
        onClick={e => onClick(e, item)}
        onKeyDown={e => onKeyDown(e, item)}
        className="treeview-group"
        {...item}
      >
        <S.TreeViewItemToggleDiv
          lineHeight={0}
          hasNestedItems={hasNestedItems}
          isRowSelected={isRowSelected(item)}
          level={level}
          onClick={e => onToggle(e, item)}
          testId={`${item.id}-tree-item`}
          {...toggleProps}
        >
          <Icon
            glyph={icons.CHEVRON_DOWN}
            rotate={isOpen ? 0 : -90}
            size={16}
          />
        </S.TreeViewItemToggleDiv>
        <Div
          cursor={
            item.groupType === DeviceGroupedType.GROUP_ONLY
              ? 'not-allowed'
              : 'pointer'
          }
          color={theme.palette.common.white}
          ml={`${theme.spacing.xxs}px`}
          {...(ellipsis() as Partial<DivProps>)}
        >
          {item.name}
        </Div>
      </S.TreeViewItemContentDiv>
      {renderSubitems()}
    </Div>
  );
}
