import '@danfoss/etui-sm-xml';
import '../../../../../constants/software-update-type.js';
import '@danfoss/etui-core';
import '../../../../../constants/software-versions.js';
import '../../../../../_rollupPluginBabelHelpers-e1ae8e90.js';
import '../../../../../utils/compare-software-versions.js';
import '../../../../../utils/get-is-misc-device.js';
import '../../../../../utils/get-version-from-filename.js';
import { useState, useEffect } from 'react';
import 'react-i18next';
import '../context/SoftwareUpdateContext.js';
import '../context/hooks/use-multi-unit-update.js';
import '../../../../../use-update-type-values-f9992c02.js';
import { useSoftwareUpdate } from '../context/hooks/use-software-update.js';

function useSoftwareUpdateDownloadFile(file) {
  const [progress, setProgress] = useState(0);
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(null);
  const [version, setVersion] = useState(null);
  const {
    filesFolder,
    latestFileUrl,
    getFileVersion
  } = useSoftwareUpdate();
  const handleSetProgress = (loaded, total) => {
    setProgress(Math.round(loaded / total * 100));
  };
  const handleResponse = async response => {
    if (!response.ok) {
      throw Error(`${response.status} ${response.statusText}`);
    }
    if (!response.body) {
      throw Error('ReadableStream not yet supported in this browser.');
    }

    // to access headers, server must send CORS header "Access-Control-Expose-Headers: content-encoding, content-length x-file-size"
    // server must send custom x-file-size header if gzip or other content-encoding is used
    const contentEncoding = response.headers.get('content-encoding');
    const contentLength = response.headers.get(contentEncoding ? 'x-file-size' : 'content-length');
    if (contentLength === null) {
      throw Error('Response size header unavailable');
    }
    const total = parseInt(contentLength, 10);
    let loaded = 0;
    return new Response(new ReadableStream({
      start(controller) {
        const reader = response.body.getReader();
        read();
        function read() {
          reader.read().then(_ref => {
            let {
              done,
              value: readValue
            } = _ref;
            if (done) {
              controller.close();
              return;
            }
            loaded += readValue.byteLength;
            handleSetProgress(loaded, total);
            controller.enqueue(readValue);
            read();
          }).catch(e => {
            controller.error(e);
          });
        }
      }
    }));
  };
  useEffect(() => {
    const downloadFile = async () => {
      setPending(true);
      setValue(null);
      setFileName(null);
      try {
        const fileNameResponse = await fetch(latestFileUrl);
        const filename = await fileNameResponse.text();
        setFileName(filename);
        setVersion(getFileVersion(filename));
        if (file && file.name === filename) {
          setPending(false);
          setValue(file);
        } else {
          const response = await fetch(`${filesFolder}/${filename}`, {
            method: 'GET'
          });
          const fileResponse = await handleResponse(response);
          const blob = await fileResponse.blob();
          const newFile = new File([blob], filename);
          setPending(false);
          setValue(newFile);
        }
      } catch (e) {
        setPending(false);
        setError(e);
      }
    };
    downloadFile();
  }, []);
  return {
    fileName,
    version,
    progress,
    pending,
    value,
    error
  };
}

export { useSoftwareUpdateDownloadFile };
