import * as React from 'react';
import { useDimensions } from 'hooks';
import { VariableSizeGrid as Grid } from 'react-window';
import { Table, TableProps } from '@danfoss/etui-core';
import { Column } from './Column';

export type As<P = any> = React.ElementType<P>;

export interface TableVirtualizedProps<T, P = {}> extends TableProps<T> {
  rowAs?: As;
  rowHeight?: number;
  rowPropsGetter?: (record: T) => P;
}

export function TableVirtualized<T>({
  rowAs = 'div',
  rowPropsGetter = null,
  rowHeight = 42,
  columns,
  scroll,
  ...props
}: TableVirtualizedProps<T>) {
  const { ref: containerRef } = useDimensions<HTMLDivElement>({
    onResize: ({ width }) => {
      setTableWidth(width);
    },
  });

  const [tableWidth, setTableWidth] = React.useState(0);

  const widthColumnCount = columns.filter(({ width }) => !width).length;
  const mergedColumns = columns.map(column => {
    if (column.width && (column.width as string).includes('%')) {
      const widthNumber = parseInt(column.width as string, 10);
      const percentAsDecimal = widthNumber / 100;
      return {
        ...column,
        width: Math.floor(percentAsDecimal * tableWidth),
      };
    }

    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: Math.floor(tableWidth / widthColumnCount),
    };
  });

  const gridRef = React.useRef<any>();
  const [connectObject] = React.useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: false,
      });
    }
  };

  React.useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (
    rawData: T[],
    { scrollbarSize, ref, onScroll }: any,
  ) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * rowHeight;
    const height = (scroll.y as number) - 71.5;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={index => {
          const { width } = mergedColumns[index];
          return totalHeight > height && index === mergedColumns.length - 1
            ? typeof width === 'number'
              ? width - scrollbarSize - 1
              : parseFloat(width) - scrollbarSize - 1
            : width;
        }}
        height={height}
        rowCount={rawData.length}
        rowHeight={() => rowHeight}
        width={tableWidth}
        itemData={{
          mergedColumns,
          rawData,
          rowAs,
          rowPropsGetter,
        }}
        onScroll={({ scrollLeft }) => {
          onScroll({ scrollLeft });
        }}
      >
        {Column}
      </Grid>
    );
  };

  return (
    <div ref={containerRef}>
      <Table
        {...props}
        testId="tableVirtualized-table"
        scroll={scroll}
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
        styles={{
          th: { width: '100%' },
        }}
      />
    </div>
  );
}
