import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { A, Div, P, Span } from '@danfoss/etui-system-elements';
import {
  Button,
  Form,
  SelectInput,
  SelectInputOption,
} from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { useForm, Controller } from 'react-hook-form';
import data from 'pages/InformationPage/ApplicationInfoData/version-updates.json';
import { getSoftwareVersion } from 'utils/get-software-version';
import {
  isElectron,
  ALSENSE_PORTAL_URL,
  DESKTOP_INSTALLER_URL,
  STOREVIEW_WEB_HOMEPAGE_URL,
} from '../../../../config';

const startIndex = 0;
const lastFiveVersions = 5;

type VersionOption = SelectInputOption & {
  link: string;
};

const buildVersionOptions = (swVersion: string): VersionOption[] => {
  const currentVersionOption = {
    value: swVersion,
    label: swVersion,
    link: '',
    isDisabled: true,
  };

  const versionsText = data.content;
  // sometimes document starts with \n which breaks parsing
  const startsWithHash = versionsText.startsWith('#');
  const regExp = /\n## (.*?)\n/g;
  const versions = Array.from(versionsText.matchAll(regExp))
    .slice(startIndex, lastFiveVersions)
    .map(coincidense => coincidense[1]);

  const linkRegExp = /\n\[link\]\((.*?)\)\n/g;
  const links = Array.from(versionsText.matchAll(linkRegExp))
    .slice(startIndex, startsWithHash ? lastFiveVersions + 1 : lastFiveVersions)
    .map(coincidense => coincidense[1]);

  const fivePrevVersions: VersionOption[] = versions.map((version, index) => ({
    label: version,
    value: version,
    link: links[startsWithHash ? index + 1 : index],
  }));

  return [currentVersionOption, ...fivePrevVersions];
};

const linkProps = {
  display: 'inline-block',
  fontWeight: 700,
  textDecoration: 'underline',
};

export const MoreInfo = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = useForm();
  const swVersion = React.useMemo(getSoftwareVersion, []);

  const N_A_VERSION_STUB: VersionOption[] = [
    {
      value: '',
      label: t('t3509'),
      link: '',
      isDisabled: true,
    },
  ];

  const versionOptions: VersionOption[] = React.useMemo(
    () => buildVersionOptions(swVersion),
    [],
  );
  const options = versionOptions.length > 1 ? versionOptions : N_A_VERSION_STUB;

  const downloadDesktopVersion = () =>
    window.open(DESKTOP_INSTALLER_URL, '_blank');

  const visitPage = page => window.open(page, '_blank');

  return (
    <Div p={`${theme.spacing.sm}px ${theme.spacing.xxlg}px`}>
      <Form>
        <Controller
          control={control}
          name="version"
          defaultValue={options[0]}
          render={({ onBlur, onChange, value }) => (
            <SelectInput
              testId="moreInfo-version-selectInput"
              name="version"
              label={t('t311')}
              onBlur={onBlur}
              onChange={(option: VersionOption) => {
                visitPage(option.link);
                onChange(option);
              }}
              value={value}
              options={options}
              styles={{ root: { width: '188px' } }}
              disabled={versionOptions.length === 1}
            />
          )}
        />
      </Form>
      {!isElectron && (
        <Div mt={`${theme.spacing.sm * 2.5}px`}>
          <P
            fontWeight={theme.typography.fontWeightBold}
            fontSize={theme.typography.htmlFontSize}
          >
            {t('t3138')}
          </P>
          <Button
            testId="moreInfo-download-button"
            width={188}
            mt={`${theme.spacing.xxs}px`}
            variant="secondary"
            onClick={downloadDesktopVersion}
          >
            {t('t2269')}
          </Button>
        </Div>
      )}

      <A
        onClick={() => visitPage(STOREVIEW_WEB_HOMEPAGE_URL)}
        mt={theme.spacing.sm * 3}
        {...linkProps}
      >
        <Span cursor="pointer">{t('t3583')}</Span>
      </A>
      <A
        onClick={() => visitPage(ALSENSE_PORTAL_URL)}
        mt={theme.spacing.sm * 3}
        {...linkProps}
      >
        <Span cursor="pointer">{t('t3510')}</Span>
      </A>
    </Div>
  );
};
