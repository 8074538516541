import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectInput, SelectInputOption } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { useConfiguration } from '../../context';

interface ConfigurationItemContentMenuDeviceGroupSelectProps {
  name?: string;
  menugroups: string[];
  selectedValue?: string;
  onChange: (groupIndex: string) => void;
}

const ConfigurationItemContentMenuDeviceGroupSelect = React.memo(
  function ConfigurationItemContentMenuDeviceGroupSelect({
    name = 'deviceGroup',
    menugroups = [],
    selectedValue,
    onChange,
  }: ConfigurationItemContentMenuDeviceGroupSelectProps) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { onGroupDropdown } = useConfiguration();

    const getOptions = (): SelectInputOption[] => {
      return menugroups.map((menugroup, index) => ({
        label: menugroup,
        value: `${index}`,
      }));
    };

    const options = getOptions();
    const selectedOption = options.find(o => o.value === selectedValue);
    const handleOnChange = item => {
      onChange(item.value);
    };

    return (
      <SelectInput
        testId="configuration-device-group-selectInput"
        key={name}
        label={t('t949')}
        name={name}
        value={selectedOption || options[0]}
        options={options}
        onChange={handleOnChange}
        onMenuOpen={() => onGroupDropdown(true)}
        onMenuClose={() => onGroupDropdown(false)}
        styles={{
          root: {
            width: ['100%', '50%'],
            borderRight: `1px solid ${theme.palette.divider}`,
          },
          input: { borderColor: 'transparent', boxShadow: 'none' },
          menu: { borderColor: 'transparent', top: '100%' },
        }}
      />
    );
  },
);

export { ConfigurationItemContentMenuDeviceGroupSelect };
