import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useUnit, useXmlResource } from '@danfoss/etui-sm';
import { SplitView, UnitsView } from '@danfoss/etui-sm/components';
import {
  Unit,
  getInitialUnitFromStorage,
  getUnitUrl,
  setSelectedUnitToStorage,
} from '@danfoss/etui-sm-xml';
import { useFeatureFlag } from 'hooks/featurehub/FeatureHubContext';
import { Alert } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { getShowBanner } from 'utils';
import {
  ConfigurationItem,
  getMenuIdsByPathname,
  usePrevious,
  TABLE_ADDRESS,
  ConfigurationAuthProvider,
} from '../Configuration';
import { UnitConfigurationBaseMenu } from './UnitConfigurationBaseMenu';
import { UnitConfigurationBaseStaleState } from './UnitConfigurationBaseStaleState';
import { UnitConfigurationBaseQuickSetup } from './UnitConfigurationBaseQuickSetup';
import { useSuspendPollingStatus } from './hooks';

type UnitConfigurationBaseProps = {
  route: string;
  onPasswordUpdatedForCurrentUser?: (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => void;
};

const UnitConfigurationBase = React.memo(
  ({ route, onPasswordUpdatedForCurrentUser }: UnitConfigurationBaseProps) => {
    const {
      location: { pathname },
    } = useHistory();

    const {
      units,
      loadState: unitsLoadState,
      loadError: unitsLoadError,
    } = useUnit();
    const { t } = useTranslation();
    const { url: xmlBackendURL } = useXmlResource();
    const initialUnit: Unit = getInitialUnitFromStorage();
    const [url, setUrl] = React.useState<string>(
      getUnitUrl(xmlBackendURL, initialUnit),
    );
    const showBanner = getShowBanner();
    const skipSessionUpdate = true;
    const pollingEnabled = useSuspendPollingStatus(
      url,
      skipSessionUpdate,
      showBanner,
    );

    const [selectedUnit, setSelectedUnit] = React.useState<Unit | null>(null);
    const handleUnitSelection = (unit: Unit) => {
      setSelectedUnit(unit);
      setSelectedUnitToStorage(unit);
      setUrl(getUnitUrl(xmlBackendURL, unit));
    };
    const prevUnits = usePrevious(units);
    const SystemConfigurationWrite = useFeatureFlag('SystemConfigurationWrite');

    const getIsMenuItemActive = React.useCallback(() => {
      const params = getMenuIdsByPathname(route, pathname);
      return params.length !== 0;
    }, [route, pathname]);

    const getIsQuickSetupItemActive = React.useCallback(() => {
      const params = getMenuIdsByPathname(route, pathname);
      return params[0] === TABLE_ADDRESS.QUICKSETUP;
    }, [route, pathname]);

    React.useEffect(() => {
      if (!units.length || prevUnits?.length) return;
      handleUnitSelection(units[0]);
    }, [units, prevUnits]);

    React.useEffect(() => setSelectedUnitToStorage(), []);

    const isLoadingUnits = unitsLoadState !== 'done';
    const isLoadingUnitsFailed = !!unitsLoadError;

    const isAnyMenuActive = getIsMenuItemActive();
    const isQuickSetupMenuActive = getIsQuickSetupItemActive();

    const handlePasswordUpdatedForCurrentUser = (
      passwordUpdated: boolean,
      updatedPassword: string,
    ) => {
      onPasswordUpdatedForCurrentUser(passwordUpdated, updatedPassword);
    };

    return (
      <ConfigurationAuthProvider
        access={{ writeAccess: SystemConfigurationWrite }}
      >
        {pollingEnabled && (
          <Alert testId="warning-Alert" type="warning" message={t('t3798')} />
        )}
        <UnitsView
          isLoading={isLoadingUnits}
          isFailed={isLoadingUnitsFailed}
          units={units}
          onRetry={window.location.reload}
        >
          <SplitView
            leftPane={
              <UnitConfigurationBaseMenu
                units={units}
                selectedUnit={selectedUnit}
                onUnitSelection={handleUnitSelection}
              />
            }
            rightPane={
              isAnyMenuActive ? (
                isQuickSetupMenuActive ? (
                  <UnitConfigurationBaseQuickSetup unit={selectedUnit} />
                ) : (
                  <ConfigurationItem
                    unit={selectedUnit}
                    options={{ showHeader: true }}
                    onPasswordUpdatedForCurrentUser={
                      handlePasswordUpdatedForCurrentUser
                    }
                  />
                )
              ) : (
                <UnitConfigurationBaseStaleState />
              )
            }
            visiblePaneOnSm={getIsMenuItemActive() ? 'right' : 'left'}
          />
        </UnitsView>
      </ConfigurationAuthProvider>
    );
  },
);

export { UnitConfigurationBase };
