import * as React from 'react';
import { useTheme, StyleProps } from '@danfoss/etui-system';
import { Div, Span } from '@danfoss/etui-system-elements';
import { ellipsis, Icon, icons, Tooltip } from '@danfoss/etui-core';
import { Skeleton } from '../Skeleton';

export interface DataItemProps<T> {
  label?: string;
  data?: T;
  dataKey?: keyof T;
  isLoading?: boolean;
  isLoadingFailed?: boolean;
  value?: string;
  fallBackValue?: string;
  mode?: 'default' | 'compact';
  styles?: {
    root?: StyleProps;
  };
  warning?: string;
}

export function DataItem<T>({
  label,
  data = {} as T,
  dataKey = '' as keyof T,
  value = null,
  fallBackValue = '-',
  isLoading = false,
  isLoadingFailed = false,
  styles = {},
  warning,
}: DataItemProps<T>) {
  const theme = useTheme();

  const displayValue = isLoadingFailed
    ? fallBackValue
    : value || data[dataKey] || fallBackValue;

  return (
    <>
      <Div testId="data-item" display="flex" {...styles.root}>
        {warning && (
          <Tooltip
            testId="dataItem-warning-tooltip"
            placement="bottom"
            message={warning}
            warning={true}
            warningWidth="200%"
            styles={{ root: { ml: '-33px' } }}
          >
            <Icon
              size={24}
              styles={{
                root: {
                  color: theme.palette.warning.main,
                  mr: '8px',
                },
              }}
              glyph={icons.WARNING}
            />
          </Tooltip>
        )}
        {label ? (
          <Span
            flexGrow="1"
            {...(theme.typography.fontSizeSmall as Object)}
            color={theme.palette.secondary.main}
            width="50%"
            title={label}
            {...(ellipsis() as any)}
          >
            {label}
          </Span>
        ) : null}
        {isLoading ? (
          <Skeleton width={!label ? '100%' : '50%'} height="24" />
        ) : (
          <Span
            width={!label ? '100%' : '50%'}
            textAlign={!label ? 'center' : 'left'}
            color={theme.palette.primary.main}
            title={warning || displayValue}
            {...(theme.typography.fontSizeSmall as Object)}
            {...(ellipsis() as any)}
          >
            {displayValue}
          </Span>
        )}
      </Div>
    </>
  );
}
