import * as React from 'react';
import {
  Modal,
  Form,
  SelectInput,
  SelectInputOption,
} from '@danfoss/etui-core';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { AxisType } from './HistoryPage.types';
import { configModalOptions } from './utils/history-constants';

export function HistoryPageConfigModal({
  isOpen,
  onClose,
  formats,
  onFormatChange,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control: unitsModalControl, handleSubmit: handleUnitsModalSubmit } =
    useForm({
      mode: 'all',
    });

  const actions: any[] = [
    { children: t('t45'), variant: 'secondary', onClick: onClose },
    {
      children: t('t44'),
      variant: 'primary',
      onClick: handleUnitsModalSubmit(values => {
        onFormatChange(
          Object.entries(values).reduce(
            (acc, [k, { value }]) => ({ ...acc, [k]: value }),
            {},
          ),
        );
        onClose();
      }),
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      testId="history-page-config-modal"
      header={
        <Modal.Header
          testId="history-page-config-modal-header"
          title={t('t335')}
        />
      }
      actionButtons={actions}
    >
      <Form>
        {configModalOptions.map(({ name, label, options }) => (
          <Controller
            key={name}
            control={unitsModalControl}
            name={name}
            defaultValue={options.find(({ value }) => value === formats[name])}
            render={({ onChange, value }) => (
              <SelectInput
                testId={`history-modal-${name}-selectInput`}
                onChange={onChange}
                value={value}
                name={name}
                options={options as SelectInputOption[]}
                label={t(label)}
                styles={{
                  root: {
                    mt:
                      name === AxisType.Pressure ? `${theme.spacing.md}px` : 0,
                    mb:
                      name === AxisType.Light
                        ? `${4 * theme.spacing.lg}px`
                        : `${theme.spacing.xxs}px`,
                  },
                }}
              />
            )}
          />
        ))}
      </Form>
    </Modal>
  );
}
