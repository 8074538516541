import {
  ConfigurationListItem,
  ConfigurationTabItem,
  fetchXMLData,
  User,
  Device,
  XML_ACTION,
} from '@danfoss/etui-sm-xml';
import { REFRIG_COPY_TABLE_ADDR } from '../types/RefrigCopy.types';

interface CopySettings {
  tableaddress: string[];
  items: { tabitem: ConfigurationTabItem[]; l: ConfigurationListItem[] };
  groupnames?: { groupname: string[] };
}

interface writeCopyConfigurationItem {
  ef?: string;
  width?: string;
  pnum?: string;
  bnum?: string;
  over?: string;
  type?: string;
  dom?: string;
  units?: string;
  token?: string;
  fVal?: string;
  authtype?: string;
  node?: string;
  mod?: string;
  point?: string;
  iotype?: string;
  value?: string;
  li?: string;
}

async function fetchCopySettings(
  url: string,
  user: User,
  tableAddress: string,
  selectedDeviceBpidx: string,
  group?: string,
) {
  try {
    const configurationData: CopySettings = await fetchXMLData({
      url,
      attributes: {
        user: user?.user,
        password: user?.password,
        lang: user?.language,
        tableaddress: tableAddress,
        version: 'C',
        index: '2',
        group,
        combo: '12',
        bpidx: selectedDeviceBpidx,
        nodetype: '16',
        storeview_only: '1',
        action: XML_ACTION.READ_LIST,
      },
    });
    return configurationData;
  } catch (e) {
    return null;
  }
}

async function writeCopyConfigurationListItem(
  url: string,
  user: User,
  audit: Record<'audit1' | 'audit2' | 'audit3' | 'audit4', string>,
  menuId: string,
  device: Device,
  deviceGroup: string,
  configuretype: string = '0',
  item: writeCopyConfigurationItem, // todo
  value: string,
  ival: string,
  newFval: string = null,
  bpidx: string,
) {
  const tableAddress = menuId;
  const index = '2';
  const { ef: enterfunc, dom: domain, fVal: fval, ...restItem } = item;
  const {
    arg1 = '0',
    arg2 = '0',
    arg3 = '0',
    stype = '',
    nodetype = '16',
    combo = '12',
  } = device || {};

  const deviceBpidx =
    tableAddress === REFRIG_COPY_TABLE_ADDR
      ? (+bpidx).toString()
      : (+bpidx - 1).toString();

  const attributes = {
    action: XML_ACTION.WRITE_LIST,
    tableAddress,
    index,
    useparent: '0',
    lang: user.language,
    isconfigure: '1',
    version: 'C',
    storeview_only: '1',
    group: deviceGroup || '0',
    // from device start
    arg1,
    arg2,
    arg3,
    stype,
    nodetype,
    combo,
    configuretype,
    bpidx: deviceBpidx,
    // from device end
    // from item start
    enterfunc,
    domain,
    ...restItem,
    // from item end
    user: user.user,
    password: user.password,
    ...audit,
    value: value || item.value,
    ival,
    fval: newFval === null ? fval : newFval,
  };

  return fetchXMLData({
    url,
    attributes,
  });
}

export { fetchCopySettings, writeCopyConfigurationListItem };
