import * as React from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Tooltip,
  ModalProps,
  TooltipPlacement,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { logoutUser, useAuth, useModal } from '@danfoss/etui-sm';
import { PATHNAMES } from '@danfoss/etui-sm-xml';
import { useConfiguration } from '../../context';
import { useFlash } from './hooks/useFlash';
import { BaseEditProps } from './ConfigurationListItemEdit';
import { ConfigurationListitemEditInputAddon } from './ConfigurationListItemEditInputAddon';
import { useConfigAuth } from './hooks/config-auth';

const USERS_TABLE_ADDR = '20085';

const UNDELETABLE_FILE_FORMATS = ['.json'];
function ConfirmationDialog({
  onSave,
  onClose,
  isOpen,
  isSecurityEnforcementChanged,
}: Partial<ModalProps> & {
  onSave: () => void;
  isSecurityEnforcementChanged: boolean;
}) {
  const { t } = useTranslation();
  const handleClose = e => {
    onClose(e);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      testId="configuration-listEdit-action-modal"
      header={
        <Modal.Header
          testId="configuration-listEdit-action-modal-header"
          title={t('t214')}
        />
      }
      actionButtons={[
        {
          children: t('t45'),
          variant: 'secondary',
          onClick: handleClose,
        },
        { children: t('t44'), onClick: onSave, variant: 'primary' },
      ]}
    >
      <Row>
        <Col xs={12}>
          <Div>{isSecurityEnforcementChanged ? t('t3776') : t('t186')}</Div>
        </Col>
      </Row>
    </Modal>
  );
}
function ConfigurationListItemEditAction({
  item,
  isAuthorized,
  isEditable,
  onSave,
  removeBtn = false,
  pathname,
}: BaseEditProps & {
  removeBtn?: boolean;
}) {
  const { t } = useTranslation();
  const { dispatch } = useAuth();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [startSuccessFlash, isSuccess] = useFlash(2000);
  const [startErrorFlash, isError] = useFlash(2000);
  const isConfirmRequired = useConfigAuth(item);
  const { location } = window;
  const isUserPage = location.hash.includes(USERS_TABLE_ADDR);
  const isFirstItem = item.li === '0';
  const enterFunction =
    item.ef === '100165' || item.ef === '100200' || item.ef === '100166';
  const isOffline = item.online === 0;
  const isEquipmentPage = pathname.includes(PATHNAMES.EQUIPMENT);
  const showTooltipOnDisableButton =
    enterFunction && isOffline && isEquipmentPage;
  const { isSecurityEnforcementChanged } = useConfiguration();
  const handleSave = React.useCallback(async () => {
    hideModal(); // close immediately. pending/error state will be shown on trigger button
    setIsSubmitting(true);

    try {
      await onSave(item, {
        value: removeBtn ? item.value : '',
        ival: removeBtn ? '1' : '',
        fval: '',
      });
      startSuccessFlash();
      isSecurityEnforcementChanged && logoutUser(dispatch);
    } catch (e) {
      startErrorFlash();
    } finally {
      setIsSubmitting(false);
    }
  }, [item, onSave]);

  const [showModal, hideModal] = useModal(ConfirmationDialog, {
    onSave: handleSave,
    item,
    isSecurityEnforcementChanged,
  });

  const isActive = isAuthorized && isEditable;

  const undeletableFile =
    removeBtn &&
    UNDELETABLE_FILE_FORMATS.some(format =>
      item.name.trimStart().split(' ')[0].endsWith(format),
    );
  const styles = { root: { whiteSpace: 'initial' } };
  const tooltipProps = showTooltipOnDisableButton
    ? {
        message: t('t3775'),
        placement: 'bottom' as TooltipPlacement,
        testId: 'configuration-listItemEdit-operationDisabled-tooltip',
      }
    : {
        message: t('t3326'),
        placement: 'left' as TooltipPlacement,
        disableToolTip: !undeletableFile,
        testId: 'configuration-listItemEdit-removeFileFormat-tooltip',
        ...styles,
      };
  return (
    <Div position="relative">
      <Tooltip {...tooltipProps}>
        <Button
          variant="secondary"
          small={true}
          block={true}
          testId="configuration-edit-tooltip-button"
          onClick={
            (!isFirstItem && isUserPage) || isConfirmRequired || removeBtn
              ? showModal
              : handleSave
          }
          disabled={!isActive || isSubmitting || undeletableFile}
        >
          {removeBtn ? t('t487') : item.name}
        </Button>
      </Tooltip>
      <ConfigurationListitemEditInputAddon
        isSubmitting={isSubmitting}
        isSucceeded={isSuccess}
        isFailed={isError}
        styles={{
          inputAddon: {
            root: {
              top: isSubmitting ? '8px' : '4px',
              right: `-28px`,
              width: '24px',
              height: '24px',
            },
          },
        }}
      />
    </Div>
  );
}

export { ConfigurationListItemEditAction };
