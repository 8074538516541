import React from 'react';
import {
  SearchInput,
  SelectInput,
  SelectInputOption,
  SpinnerSize,
  Table,
  Toggle,
} from '@danfoss/etui-core';
import { Div, Span } from '@danfoss/etui-system-elements';
import { useTheme } from 'styled-components';
import { ContentSpinner, EmptyState } from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import { delay } from '../../../../../Configuration/utils';
import { ConfigurationListitemEditInputAddon } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItemEditInputAddon';
import {
  SourceGraphTable,
  SourceGraphTableContent,
  SourceGraphTableData,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { generateNumberArray } from '../../../RefrigLayoutModal/utils';
import { setHaccpStatus, setLogStatus } from './actions';

export interface SourceGraphFlowTableProps {
  handleOnLogChange: (
    selectedOption: SelectInputOption,
    content: SourceGraphTableContent,
    selectedRowIndex: number,
  ) => void;
  handleOnHaccpToggle: (
    isChecked: boolean,
    content: SourceGraphTableContent,
    selectedRowIndex: number,
  ) => void;
}

export const SourceGraphFlowTable = ({
  handleOnLogChange,
  handleOnHaccpToggle,
}: SourceGraphFlowTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    sourceGraphTableData,
    multipage,
    searchMode,
    isAllPageDataFetched,
    isPageDataFetched,
    saveCompleted,
    selectedRowIndex,
    setPage,
    setPageValues,
    onSetSearchValue,
    setSaveCompleted,
  } = useRefrigCopy();
  const [sourceGraphTable, setSourceGraphTable] = React.useState<
    SourceGraphTable[]
  >([]);
  const [sourceGraphTablePage, setSourceGraphTablePage] =
    React.useState<SelectInputOption[]>();
  const [sourceGraphTableSelectedPage, setSourceGraphTableSelectedPage] =
    React.useState<SelectInputOption>({
      value: '1',
      label: '1',
    });
  React.useEffect(() => {
    if (sourceGraphTableData?.sourceGraphTableContent?.length) {
      getTableData(sourceGraphTableData);
      if (!searchMode) {
        getSourceGraphPages();
      }
    }
  }, [sourceGraphTableData]);

  React.useEffect(() => {
    const stopAnimation = async () => {
      await delay(500);
      setLogStatus(sourceGraphTableData, selectedRowIndex, false, false, false);
      setHaccpStatus(
        sourceGraphTableData,
        selectedRowIndex,
        false,
        false,
        false,
      );
      getTableData(sourceGraphTableData);
    };
    if (saveCompleted) {
      stopAnimation();
    }
  }, [saveCompleted]);

  const getSourceGraphPages = () => {
    const pageValues: number[] = generateNumberArray(1, +multipage);
    const sourceGraphPageOptions: SelectInputOption[] = [];
    pageValues.forEach(page => {
      sourceGraphPageOptions.push({
        value: page.toString(),
        label: page.toString(),
      });
    });
    setPageValues(pageValues);
    setSourceGraphTablePage(sourceGraphPageOptions);
  };

  const handleOnPageChange = (options: SelectInputOption) => {
    const page: number = +options.label - 1;
    setSourceGraphTableSelectedPage(options);
    setPage(page.toString());
  };

  const columns = [
    {
      title: t('t35').toUpperCase(),
      dataIndex: 'point',
      key: 'point',
      width: '15%',
    },
    {
      title: t('t938').toUpperCase(),
      dataIndex: 'log',
      key: 'log',
      width: '15%',
    },
    {
      title: t('t3110').toUpperCase(),
      dataIndex: 'haccp',
      key: 'haccp',
    },
  ];

  const getTableData = (sourceGraphTableData: SourceGraphTableData) => {
    const sourceGraphTableDataList: SourceGraphTable[] = [];
    sourceGraphTableData?.sourceGraphTableContent?.forEach((content, index) => {
      sourceGraphTableDataList.push({
        point: content.point,
        log: (
          <Div
            testId="source-graph-flow-log-div"
            width="70%"
            mt="-10px"
            mb="-10px"
          >
            <SelectInput
              testId="source-graph-flow-selectInput"
              name=""
              value={{ label: content?.log, value: '' }}
              options={content?.logList}
              size="small"
              onChange={(e: SelectInputOption) => {
                setSaveCompleted(false);
                handleOnLogChange(e, content, index);
              }}
              disabled={
                content?.isLogSubmitting ||
                content?.isLogSucceeded ||
                content?.isLogFailed
              }
              endInputAddon={
                content?.isLogSubmitting ||
                content?.isLogSucceeded ||
                content?.isLogFailed ? (
                  <ConfigurationListitemEditInputAddon
                    isSubmitting={content?.isLogSubmitting}
                    isSucceeded={content?.isLogSucceeded}
                    isFailed={content?.isLogFailed}
                    styles={{
                      inputAddon: {
                        root: content?.isLogSubmitting
                          ? {
                              top: '9px',
                              width: '24px',
                              height: '24px',
                              right: `${theme.spacing.xxs}px`,
                            }
                          : {
                              top: '5px',
                              width: '32px',
                              height: '32px',
                              right: `${theme.spacing.xxs}px`,
                            },
                      },
                    }}
                  />
                ) : null
              }
            />
          </Div>
        ),
        haccp: (
          <Div
            testId="source-graph-flow-haccp-div"
            style={{
              width: '60%',
              display: 'inline-block',
            }}
          >
            <Span
              style={{
                display: 'inline-block',
              }}
            >
              <Toggle
                name={`toggle-${content.point}-${content.haccp}`}
                isChecked={content.haccp}
                onChange={(_, isChecked) => {
                  setSaveCompleted(false);
                  handleOnHaccpToggle(isChecked, content, index);
                }}
                isDisabled={
                  content?.isHaccpSubmitting ||
                  content?.isHaccpSucceeded ||
                  content?.isHaccpFailed
                }
              />
            </Span>
            <Span
              style={{
                marginLeft: '10px',
                display: 'inline-block',
              }}
            >
              <ConfigurationListitemEditInputAddon
                isSubmitting={content?.isHaccpSubmitting}
                isSucceeded={content?.isHaccpSucceeded}
                isFailed={content?.isHaccpFailed}
                styles={{
                  inputAddon: {
                    root: {
                      top: content?.isHaccpSubmitting ? '' : '-28px',
                      right: content?.isHaccpSubmitting ? 'auto' : '6px',
                      position: 'relative',
                    },
                  },
                }}
              />
            </Span>
          </Div>
        ),
      });
    });
    setSourceGraphTable(sourceGraphTableDataList);
    setSaveCompleted(false);
  };

  return (
    <>
      <Div
        testId="source-graph-flow-table-search-page-div"
        style={{
          marginTop: '20px',
          width: '60%',
          display: 'inline-block',
        }}
      >
        <Span
          style={{
            width: '30%',
            display: 'inline-block',
          }}
        >
          <SearchInput
            placeholder={t('t3401')}
            testId="config-graphPoint-searchInput"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onSetSearchValue(e.target.value)
            }
            styles={{
              input: {
                borderColor: 'grey',
                background: 'white',
              },
            }}
          />
        </Span>
        {!searchMode ? (
          <Span
            style={{
              width: '30%',
              marginLeft: '10px',
              display: 'inline-block',
            }}
          >
            <SelectInput
              testId="source-graph-flow-selectInput"
              name=""
              options={sourceGraphTablePage}
              value={sourceGraphTableSelectedPage}
              onChange={handleOnPageChange}
              size="small"
            />
          </Span>
        ) : null}
      </Div>
      {!isPageDataFetched || (searchMode && !isAllPageDataFetched) ? (
        <Div
          testId="source-graph-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : !sourceGraphTableData?.sourceGraphTableContent?.length ? (
        <Div
          testId="source-graph-flow-table-empty-div"
          style={{ marginTop: '200px' }}
        >
          <EmptyState title={t('t3402')} />
        </Div>
      ) : (
        <Div
          testId="source-graph-flow-table-content-div"
          style={{ marginTop: '20px' }}
        >
          <Table
            testId="source-graph-flow-table"
            rowKey="source-graph-table-content"
            columns={columns}
            dataSource={sourceGraphTable}
            pagination={false}
            emptyPlaceholder={null}
            styles={{
              td: { p: `${theme.spacing.sm}px`, height: 10 },
              root: { overflow: 'visible' },
            }}
          />
        </Div>
      )}
    </>
  );
};
