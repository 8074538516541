import React, { useState, useRef } from 'react';
import { usePopper } from 'react-popper';
import { ClickOutside } from '@danfoss/etui-core';
import { DropdownMenuDiv } from './styles';

function DefaultButton(props) {
  return (
    <span className="dots" {...props}>
      ...
    </span>
  );
}

type DropdownProps = {
  button?: any;
  children: any;
  wrapperStyle?: React.CSSProperties;
  closeOnSelect?: boolean;
};

const noop = () => {};

function Dropdown({
  button,
  children,
  wrapperStyle = {},
  closeOnSelect = false,
}: DropdownProps) {
  const [visible, setVisible] = useState(false);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const { styles, attributes, update } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom-start',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'computeStyles',
          options: {
            adaptive: false, // true by default
          },
        },
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, -5],
          },
        },
      ],
    },
  );

  const hide = () => setVisible(false);

  function handleDropdownClick(e: any) {
    e.preventDefault();
    setVisible(true);
  }

  React.useLayoutEffect(() => {
    /**
     * prevents styles.popper providing incorrect translate property
     * making popper out of screen when resizing to and from mobile view
     */
    typeof update === 'function' && update();
  }, [visible]);

  return (
    <>
      {button ? (
        React.cloneElement(button, {
          elRef: referenceRef,
          onClick: handleDropdownClick,
        })
      ) : (
        <DefaultButton />
      )}
      <ClickOutside testId="dropdown-clickOutside" onClickOutside={hide}>
        <div
          ref={popperRef}
          style={{ ...styles.popper, ...wrapperStyle }}
          {...attributes.popper}
        >
          <DropdownMenuDiv
            style={styles.offset}
            isVisible={visible}
            onClick={closeOnSelect ? hide : noop}
            testId="dropDown-menu-div"
          >
            {children}
          </DropdownMenuDiv>
        </div>
      </ClickOutside>
    </>
  );
}

export { Dropdown };
