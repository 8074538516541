import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import {
  Button,
  icons,
  TreeItem,
  TreeViewProps,
  Badge,
  Tooltip,
  ellipsis,
} from '@danfoss/etui-core';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemToggle,
  AccordionItemPanel,
  Measurement,
} from '@danfoss/etui-sm';
import { DeviceType, DeviceGrouped } from '@danfoss/etui-sm/types';
import { Div } from '@danfoss/etui-system-elements';
import { getDeviceTypeTextId } from 'utils';
import { MenuItem } from '../MenuItem';
import { DeviceTypeIcon } from '../DeviceTypeIcon';
import { getNestedItemById } from './utils';

export type DeviceBasedMenuItemProps = {
  data: { [key in DeviceType]?: DeviceGrouped[] };
  type: DeviceType;
  enableFilter?: boolean;
  checkedItems?: TreeViewProps['checkedItems'];
  setCheckedItems?: (i: any) => void;
  rowTemplate?: TreeViewProps['rowTemplate'];
  getChildrenToCheck?: (item: TreeItem) => string[];
  onMenuItemClick?: (item: any, items?: any) => void;
  onClearCheckedItems?: (items: any) => void;
  selectedRow?: any;
  defaultOpenDevice?: string;
  isFilterDisabled?: boolean;
  formats?: Measurement;
};

function DeviceBasedMenuItem({
  renderEntry,
  isOfMinHeight,
  deviceTypes,
  type,
  height,
  data,
  checkedItems,
  onClearCheckedItems,
}: any) {
  const theme = useTheme();
  const { t } = useTranslation();

  const textId = type === 'time-range' ? 't436' : getDeviceTypeTextId(type);

  const treeData = data && type !== 'time-range' ? data[type] : [];

  const checkedItemsById = (checkedItems || [])
    .map(id => getNestedItemById(treeData, id))
    .filter(Boolean);

  const checkedItemsCount =
    checkedItemsById.filter(checkedItem => !checkedItem.items).length || false;

  const heightOfItems = deviceTypes.length * 51;

  return (
    <AccordionItem key={type} uuid={type}>
      <AccordionItemHeading
        as={MenuItem}
        testId={`deviceBaseMenu-${MenuItem.Content ?? ''}-accordion`}
      >
        {({ expanded, disabled }) => (
          <AccordionItemToggle as={MenuItem.Content}>
            <Button
              variant="tertiary"
              iconProps={{
                glyph: icons.CHEVRON_DOWN,
                size: 16,
                rotate: expanded ? 0 : -90,
              }}
              small={true}
              styles={{
                root: { p: `${theme.spacing.xs}px` },
              }}
              disabled={disabled}
              testId="toggle-accordion-button"
            />
            <DeviceTypeIcon deviceType={type} />
            <Div title={t(textId)} {...(ellipsis() as any)}>
              {t(textId)}
            </Div>
            {checkedItemsCount && (
              <Div
                position="absolute"
                right={`${theme.spacing.xxs}px`}
                display="flex"
                alignItems="center"
              >
                <Badge
                  text={checkedItemsCount.toString()}
                  styles={{
                    root: {
                      bg: theme.palette.black['20'],
                      mr: `${theme.spacing.xxs}px`,
                      lineHeight: 0,
                    },
                  }}
                />

                <Tooltip
                  testId="deviceMenu-tooltip"
                  message={t('t966')}
                  placement="left"
                >
                  <Button
                    variant="tertiary"
                    iconProps={{
                      glyph: icons.CLOSE,
                      size: 16,
                    }}
                    testId="deviceMenu-tooltip-button"
                    onClick={() => onClearCheckedItems(checkedItemsById)}
                    small={true}
                    styles={{
                      root: {
                        p: `${theme.spacing.xs}px`,
                      },
                    }}
                  />
                </Tooltip>
              </Div>
            )}
          </AccordionItemToggle>
        )}
      </AccordionItemHeading>
      <AccordionItemPanel
        testId="deviceBaseMenuItem-accordion"
        height={isOfMinHeight ? '' : `${height - heightOfItems}px`}
        overflow={isOfMinHeight ? 'visible' : 'auto'}
        borderBottom={`1px solid ${theme.palette.divider}`}
        bg={theme.palette.common.bg}
      >
        {({ expanded }) => (expanded ? renderEntry(type) : null)}
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export { DeviceBasedMenuItem };
