import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@danfoss/etui-core';
import { Unit } from '@danfoss/etui-sm-xml';
import { useModal } from '@danfoss/etui-sm';
import { useRefrigLayout } from '../../RefrigLayoutModal/context';
import { WizardConfirmationModal } from '../../WizardConfirmationModal';
import { HVACLayoutContent } from './components/HVACLayoutContent';
import { HVACLayoutModalActions } from './HVACLayoutModalActions';

export interface HVACLayoutInnerModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: Unit;
}
function HVACLayoutInnerModal({
  isOpen,
  onClose,
  unit,
}: HVACLayoutInnerModalProps) {
  const { t } = useTranslation();
  const { setUnit } = useRefrigLayout();

  const handleOnOkClick = () => {
    closeModal();
  };

  const closeModal = () => {
    onClose();
  };

  const [showWizardCloseModal] = useModal(WizardConfirmationModal, {
    handleOnOkClick,
    title: t('t3382'),
    text: t('t3383'),
    subText: t('t3380'),
  });
  React.useEffect(() => {
    if (unit) {
      setUnit(unit);
    }
  }, [unit]);

  const handleOnClose = () => {
    showWizardCloseModal();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      testId="hvac-inner-modal"
      header={
        <Modal.Header
          testId="hvac-inner-modal-header"
          title={t('t501')}
          description={t('t87')}
        />
      }
      actions={
        <HVACLayoutModalActions
          onCancel={handleOnClose}
          handleOnFinish={closeModal}
        />
      }
      style={{
        content: {
          width: '100%',
          height: '100%',
          maxWidth: '100%',
        },
        overlay: {},
      }}
    >
      <HVACLayoutContent />
    </Modal>
  );
}

export { HVACLayoutInnerModal };
