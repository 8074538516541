import * as React from 'react';
import { SelectInput } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import {
  ConfigurationTabItem,
  Device,
  XML_DEVICE_COMBO,
} from '@danfoss/etui-sm-xml';
import { useTranslation } from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';

export interface ConfigurationItemContentMenuDeviceSelectProps {
  devices: Device[];
  selectedDevice?: Device;
  onDeviceChange: (device: Device) => void;
  combo: ConfigurationTabItem['combo'];
}

const getUniqueProp = ({
  addr = '0',
  arg1 = '0',
  arg2 = '0',
  arg3 = '0',
  arg4 = '0',
  bpidx = '0',
  comboindex = '0',
}: Device): string =>
  `${addr}${arg1}${arg2}${arg3}${arg4}${bpidx}${comboindex}`;

function ConfigurationItemContentMenuDeviceSelect({
  devices,
  selectedDevice,
  onDeviceChange,
  combo,
}: ConfigurationItemContentMenuDeviceSelectProps) {
  if (!selectedDevice) return null;

  const theme = useTheme();
  const { t } = useTranslation();
  const isSched = combo === XML_DEVICE_COMBO.COMBO_SCHEDS;
  const title = isSched ? t('t420') : t('t963');

  const options = React.useMemo(
    () =>
      devices.map(device => ({
        label: device.name,
        value: getUniqueProp(device),
      })),
    [devices],
  );

  const selectedOption = selectedDevice
    ? options.find(o => o.value === getUniqueProp(selectedDevice))
    : options[0];

  const [device, setDevice] = React.useState(selectedOption);
  const handleOnChange = item => {
    const newDevice = devices.find(d => getUniqueProp(d) === item.value);
    setDevice(item);
    onDeviceChange(newDevice);
  };

  useDeepCompareEffect(() => {
    const deviceToSelect = options.find(
      o => o.value === getUniqueProp(selectedDevice),
    );
    setDevice(deviceToSelect);
  }, [selectedDevice || {}]);

  if (!options.length) return null;

  return (
    <SelectInput
      testId={`configuration-menu-${title}-selectInput`}
      label={title}
      name="device"
      value={device}
      options={options}
      onChange={handleOnChange}
      styles={{
        root: {
          width: ['100%', '25%'],
          borderRight: `1px solid ${theme.palette.divider}`,
        },
        input: { borderColor: 'transparent', boxShadow: 'none' },
        menu: { borderColor: 'transparent', top: '100%' },
      }}
    />
  );
}

export { ConfigurationItemContentMenuDeviceSelect };
