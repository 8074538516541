import * as React from 'react';
import { Modal, Notification, useResponsive } from '@danfoss/etui-core';
import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { useAuth, useXmlResource } from '@danfoss/etui-sm';
import {
  addStagingPatternTableData,
  fetchPatternData,
  filterStagingPatternTableData,
  getStagingPatternDataToWrite,
  processPatternData,
  updateDefaultPatternData,
  updateOnCompressorClick,
  updateOnUnloaderClick,
  writePatternData,
} from '../../actions/configuration-staging-pattern-actions';
import {
  StagingPatternResponse,
  StagingPatternTableData,
  STEPS,
} from './types';
import { ConfigurationListItemEditStagingPatternButtons } from './ConfigurationListItemEditStagingPatternButtons';
import { ConfigurationListItemEditStagingPatternTable } from './ConfigurationListItemEditStagingPatternTable';
import { ConfigurationListItemEditStagingPatternStepLabel } from './ConfigurationListItemEditStagingPatternStepLabel';

export interface ConfigurationListItemEditStagingPatternModalProps {
  item: ConfigurationListItem;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfigurationListItemEditStagingPatternModal = ({
  item,
  isOpen,
  onClose,
}: ConfigurationListItemEditStagingPatternModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuth();
  const { url } = useXmlResource();
  const { screenIsAtMost } = useResponsive({
    xs: parseInt(theme.breakpoints[0], 10),
  });
  const isXs = screenIsAtMost('xs', ['portrait', 'landscape']);
  const step = item.value === STEPS.MIN_STEP ? STEPS.DEFAULT_STEP : item.value;

  const [stagingPatternTableData, setStagingPatternTableData] = React.useState<
    StagingPatternTableData[]
  >([]);
  const [filteredStagingPatternTableData, setFilteredStagingPatternTableData] =
    React.useState<StagingPatternTableData[]>([]);
  const [emptyTableDataSource, setEmptyTableDataSource] =
    React.useState<boolean>(false);
  const [stepValue, setStepValue] = React.useState<string>(step);
  const [patternData, setPatternData] =
    React.useState<StagingPatternResponse>(null);

  React.useEffect(() => {
    const getPatternData = async () => {
      const patternDataResp: StagingPatternResponse = await fetchPatternData(
        url,
        user,
        item.arg1,
      );
      if (patternDataResp.error !== '0') {
        Notification.error({
          message: t('t17'),
          description: t('t3788'),
          duration: 3,
          theme,
          testId: 'configuration-listItem-fetchStagePatterns-notification',
        });
        onClose();
      }
      if (patternDataResp) {
        const updatedPatternDataResp: StagingPatternResponse =
          updateDefaultPatternData(patternDataResp);
        setPatternData(updatedPatternDataResp);
      }
    };
    if (user && url) {
      getPatternData();
    }
  }, []);

  React.useEffect(() => {
    if (patternData) {
      const stagingPatternTableData: StagingPatternTableData[] =
        processPatternData(patternData);
      setStagingPatternTableData(stagingPatternTableData);
    }
  }, [patternData]);

  const handleCompressorClick = (rowIndex: number, compIndex: number) => {
    if (rowIndex !== 0) {
      const updatedStagingPatternTableData: StagingPatternTableData[] =
        updateOnCompressorClick(
          rowIndex,
          compIndex,
          filteredStagingPatternTableData.length
            ? filteredStagingPatternTableData
            : stagingPatternTableData,
        );
      setStagingPatternTableData([...updatedStagingPatternTableData]);
    }
  };

  const handleUnloaderClick = (
    rowIndex: number,
    compIndex: number,
    unloaderIndex: number,
  ) => {
    if (rowIndex !== 0) {
      const updatedStagingPatternTableData: StagingPatternTableData[] =
        updateOnUnloaderClick(
          rowIndex,
          compIndex,
          unloaderIndex,
          filteredStagingPatternTableData.length
            ? filteredStagingPatternTableData
            : stagingPatternTableData,
        );
      setStagingPatternTableData([...updatedStagingPatternTableData]);
    }
  };

  const handleStepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let step = +e.target.value;
    step =
      !step || step < +STEPS.MIN_STEP
        ? +STEPS.MIN_STEP
        : step > +STEPS.MAX_STEP
        ? +STEPS.MAX_STEP
        : step;
    const originalStep: number = +item.value;
    if (step === +STEPS.MIN_STEP) {
      setStepValue(step.toString());
      setEmptyTableDataSource(true);
    } else if (step >= originalStep && step >= stagingPatternTableData.length) {
      setStepValue(step.toString());
      const clonedPatternData: StagingPatternResponse = JSON.parse(
        JSON.stringify(patternData),
      );
      const updatedStagingPatternTableData: StagingPatternTableData[] =
        addStagingPatternTableData(
          clonedPatternData,
          step,
          stagingPatternTableData,
        );
      setFilteredStagingPatternTableData([]);
      setEmptyTableDataSource(false);
      setStagingPatternTableData(updatedStagingPatternTableData);
    } else if (step <= stagingPatternTableData.length) {
      setStepValue(step.toString());
      const clondedStagingPatternTableData = JSON.parse(
        JSON.stringify(stagingPatternTableData),
      );
      const filteredStagingPatternTableData: StagingPatternTableData[] =
        filterStagingPatternTableData(step, clondedStagingPatternTableData);
      setEmptyTableDataSource(false);
      setFilteredStagingPatternTableData(filteredStagingPatternTableData);
    }
  };

  const handleOnOkClick = async () => {
    const patternsToWrite: number[] = getStagingPatternDataToWrite(
      stagingPatternTableData,
      +stepValue,
    );
    const writePatternDataResp: StagingPatternResponse = await writePatternData(
      user,
      url,
      item.arg1,
      stepValue,
      patternsToWrite,
    );
    if (writePatternDataResp.error !== '0') {
      Notification.error({
        message: t('t17'),
        description: t('t3789'),
        duration: 3,
        theme,
        testId: 'configuration-listItem-error--fetStagePatterns-notification',
      });
    } else {
      Notification.success({
        message: t('t3123'),
        description: t('t3790'),
        duration: 3,
        theme,
        testId: 'configuration-listItem-success-fetStagePatterns-notification',
      });
    }
  };

  return (
    <>
      {patternData ? (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          testId="configuration-listEdit-stagePattern-modal"
          fullPageSize={isXs}
          shouldCloseOnOverlayClick={false}
          style={{
            content: {
              maxHeight: 700,
              minHeight: 700,
              minWidth:
                +patternData.t_comp <= 5
                  ? 750
                  : +patternData.t_comp === 6
                  ? 900
                  : +patternData.t_comp === 7
                  ? 1000
                  : +patternData.t_comp === 8
                  ? 1100
                  : 1200,
              marginTop: 5,
            },
          }}
          header={
            <Div testId="staging-pattern-header-div">
              <Modal.Header
                testId="configuration-listEdit-stagePattern-modal-header"
                title={t('t3791')}
                showCloseButton={false}
              />
              <ConfigurationListItemEditStagingPatternStepLabel
                stepValue={stepValue}
                handleStepChange={handleStepChange}
              />
            </Div>
          }
          actions={
            <ConfigurationListItemEditStagingPatternButtons
              onClose={onClose}
              handleOnOkClick={handleOnOkClick}
            />
          }
        >
          <ConfigurationListItemEditStagingPatternTable
            stagingPatternTableData={stagingPatternTableData}
            filteredStagingPatternTableData={filteredStagingPatternTableData}
            emptyTableDataSource={emptyTableDataSource}
            handleCompressorClick={handleCompressorClick}
            handleUnloaderClick={handleUnloaderClick}
          />
        </Modal>
      ) : null}
    </>
  );
};
