import { useDashboard, WidgetComponentBase } from '@danfoss/etui-core';
import React from 'react';
import { AlarmsTile } from '../AlarmsTile';

export const DashboardAlarmsWidget = props => {
  const { id } = props;
  const [, dispatch] = useDashboard();
  return (
    <WidgetComponentBase {...props} testId="dashboardAlarms-widget">
      <AlarmsTile
        rowCount={1}
        onMaximize={() => dispatch({ type: 'SET_FULLSCREEN', id })}
        onMinimize={() => dispatch({ type: 'REMOVE_FULLSCREEN' })}
        isWidget={true}
        {...props}
      />
    </WidgetComponentBase>
  );
};
