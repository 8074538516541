import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import {
  Button,
  ColumnProps,
  Icon,
  icons,
  InputAddon,
  Table,
  TextInput,
  Tooltip,
  useResponsive,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import {
  EmptyState,
  getLanguageCodeByName,
  Protocol,
  sortStringAlphabetically,
  useApp,
  useAuth,
  useModal,
  useSearch,
} from '@danfoss/etui-sm';
import {
  addRecentSiteToAddressBook,
  verifyAddressBookPassword,
  getRecentSites,
  isAlreadyInAddressBook,
  getAddressBookPasswordHash,
} from 'utils/addressbook-helpers';
import { AddressBookItem, NOT_AVAILABLE } from 'types';
import { openSiteInANewTab } from 'pages/LoginPage/utils/openNewBrowserTab';
import { isElectron } from '../../../../config';
import { AddressBook } from '../AddressBook';
import { showErrorNotification, showSuccessNotification } from '../../utils';
import { usePasswordPrompt } from '../../hooks';

const SEARCH_BLOCK_HEIGHT = 40;

export const RecentSites = ({ switchToLoginForm }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuth();
  const [state, dispatch] = useApp();
  const langCode = getLanguageCodeByName(user?.language);
  const recentSites = getRecentSites();
  const updateView = () => updateInitData(getRecentSites());

  const { screenIsAtMost } = useResponsive({
    sm: parseInt(theme.breakpoints[0], 10),
  });
  const isSm = screenIsAtMost('sm', ['portrait', 'landscape']);

  const {
    data: filteredTableData,
    searchValue,
    onSearchInputChange,
    updateInitData,
  } = useSearch(recentSites, ['name', 'ipAddress']);

  const {
    setPromptMessage,
    onPromptResolve,
    showPasswordPrompt,
    closePasswordPrompt,
    setPromptButtonLabel,
  } = usePasswordPrompt();

  const addToAddressBook = (site: AddressBookItem) => {
    const hash = getAddressBookPasswordHash();

    const handleAddressBookPwdInput = (addressBookPassword: string) => {
      try {
        verifyAddressBookPassword(addressBookPassword);

        const handleSitePwdInput = (sitePassword: string | undefined) => {
          try {
            if (sitePassword === undefined) return;
            if (sitePassword === '') throw new Error('t3493');

            addRecentSiteToAddressBook(site, sitePassword, addressBookPassword);
            showSuccessNotification(theme, t, t('t3468'));
            closePasswordPrompt();
            updateView();
          } catch (e) {
            showErrorNotification(theme, t, e);
          }
        };

        setPromptMessage('t3498');
        onPromptResolve(handleSitePwdInput);
        closePasswordPrompt();
        setTimeout(showPasswordPrompt, 0);
      } catch (e) {
        showErrorNotification(theme, t, e);
      }
    };
    setPromptButtonLabel(hash ? 't74' : 't266');
    setPromptMessage(hash ? 't3489' : 't3807');
    onPromptResolve(handleAddressBookPwdInput);
    showPasswordPrompt();
  };

  const handleLoginButtonClick = (site: AddressBookItem) => {
    if (state.protocol !== site.protocol.value) {
      if (isElectron) {
        dispatch({
          type: 'SET_PROTOCOL',
          payload:
            state.protocol === Protocol.http ? Protocol.https : Protocol.http,
        });
        switchToLoginForm(site, true);
      } else {
        openSiteInANewTab(site);
      }
    } else {
      switchToLoginForm(site, true);
    }
  };

  const [openAddressBook] = useModal(AddressBook, {
    switchToLoginForm,
    updateRecentsView: updateView,
  });

  const columns: ColumnProps<AddressBookItem>[] = [
    {
      title: t('t76'),
      key: 'name',
      dataIndex: 'name',
      width: '30%',
      ellipsis: true,
      sorter: (a: AddressBookItem, b: AddressBookItem) =>
        sortStringAlphabetically(a.name, b.name, langCode),
      render: (name: string) => name || NOT_AVAILABLE,
    },
    {
      title: t('t3386'),
      key: 'ipAddress',
      dataIndex: 'ipAddress',
      width: '48%',
      ellipsis: true,
      sorter: (a: AddressBookItem, b: AddressBookItem) =>
        sortStringAlphabetically(a.ipAddress, b.ipAddress, langCode),
    },
    {
      title: t('t3478'),
      key: 'loginUser',
      width: '22%',
      render: (site: AddressBookItem) => (
        <Div display="flex">
          <Tooltip
            testId="recentSite-addAddress-tooltip"
            message={t('t3470')}
            placement="left"
            disableToolTip={isSm || isAlreadyInAddressBook(site)}
          >
            <Button
              disabled={isAlreadyInAddressBook(site)}
              iconProps={{ glyph: icons.LIST_VIEW_BORDERED, size: 16 }}
              testId="recentSite-addAddress-button"
              onClick={() => addToAddressBook(site)}
              p={`${theme.spacing.xs}px`}
              small={true}
              variant="tertiary"
            />
          </Tooltip>
          <Tooltip
            testId="recentSite-loginBack-tooltip"
            message={t('t3471')}
            placement="left"
            disableToolTip={isSm}
          >
            <Button
              iconProps={{ glyph: icons.CHEVRON_DOWN, size: 16, rotate: -90 }}
              testId="recentSite-loginBack-button"
              onClick={() => handleLoginButtonClick(site)}
              p={`${theme.spacing.xs}px`}
              small={true}
              variant="tertiary"
            />
          </Tooltip>
        </Div>
      ),
    },
  ];

  return (
    <>
      <Div
        display="flex"
        height={SEARCH_BLOCK_HEIGHT}
        mb={`${theme.spacing.md}px`}
        p={`0 ${theme.spacing.xlg}px`}
      >
        <TextInput
          styles={{
            root: { flex: 1 },
            input: { height: `${SEARCH_BLOCK_HEIGHT}px` },
          }}
          placeholder={t('t3500')}
          value={searchValue}
          testId="recentSite-search-input"
          onChange={onSearchInputChange}
          disabled={!recentSites.length}
          startInputAddon={
            <InputAddon
              position="start"
              testId="recentSite-search-inputAddOn"
              isPointerEventsDisabled={true}
              styles={{
                root: {
                  top: `9px`,
                  width: '24px',
                  height: '24px',
                },
              }}
            >
              <Icon glyph={icons.SEARCH} />
            </InputAddon>
          }
        />
        <Button
          variant="primary"
          ml={theme.spacing.md}
          testId="recentSite-openAddress-button"
          onClick={openAddressBook}
          height={40}
        >
          {t('t3458')}
        </Button>
      </Div>
      <Table
        data-testid="recents-table"
        rowKey={({ id }: AddressBookItem) => id}
        columns={columns}
        dataSource={filteredTableData}
        pagination={false}
        emptyPlaceholder={<EmptyState title={t('t3402')} />}
      />
    </>
  );
};
