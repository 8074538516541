import React from 'react';
import { Modal, Notification, Spinner, SpinnerSize } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useAuth, useXmlResource } from '@danfoss/etui-sm';
import { Div } from '@danfoss/etui-system-elements';
import { useTheme } from '@danfoss/etui-system';
import { isXmlError, Unit } from '@danfoss/etui-sm-xml';
import {
  fetchDiscoveryDataByUnit,
  getScanStatus,
  startRescan,
} from '../../actions';
import {
  DEVICE_SCAN_STATUS,
  DiscoveryPageData,
  DiscoveryTableData,
  RescanResponse,
  ScanStatusResponse,
} from '../../types/DiscoveryPage.types';
import { delay } from '../../../../../Configuration/utils';
import { fetchHvacDiscoveryDataByUnit } from '../../../HVACModules/HVACLayoutModal/actions/hvac-discovery-flow-actions';

interface DiscoveryFlowRescanModalProps {
  isOpen: boolean;
  onClose: () => void;
  onRescanComplete: (rescannedPageData: DiscoveryPageData) => void;
  isOfflineProgramming: boolean;
  discoveryTableData: DiscoveryTableData[];
  isHvac: boolean;
  shouldStartRescan: boolean;
  unit: Unit;
}
export const DiscoveryFlowRescanModal = ({
  isOpen,
  onClose,
  onRescanComplete,
  isOfflineProgramming,
  discoveryTableData,
  isHvac,
  shouldStartRescan,
  unit,
}: DiscoveryFlowRescanModalProps) => {
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const [scanStatus, setScanStatus] = React.useState<string>(t('t3345'));
  const [isScanning, setIsScanning] = React.useState<boolean>(true);
  const [rescanResponse, setRescanResponse] =
    React.useState<RescanResponse>(null);

  const rescan = async () => {
    try {
      const data: RescanResponse = await startRescan([
        xmlBackendURL,
        user,
        unit,
      ]);
      setRescanResponse(data);
    } catch (error) {
      if (isXmlError(error)) {
        onClose();
        Notification.error({
          message: t('t17'),
          description: t('t3348'),
          duration: 3,
          theme,
          testId: 'discoveryFlow-rescan-notification',
        });
      }
    }
  };

  React.useEffect(() => {
    const rescanDevices = async () => {
      await rescan();
    };
    if (shouldStartRescan && scanStatus === t('t3345')) {
      rescanDevices();
    }
  }, [shouldStartRescan]);

  const checkScanStatusAndFetchPageData = async () => {
    try {
      const scanStatusResponse: ScanStatusResponse = await getScanStatus([
        xmlBackendURL,
        user,
        unit,
      ]);
      const scanStatus = +scanStatusResponse?.scan_status;
      const maxNodes = +scanStatusResponse?.max_nodes;
      if (scanStatusResponse?.scan_status && scanStatusResponse?.max_nodes) {
        if (scanStatus > 0 && scanStatus <= maxNodes) {
          setScanStatus(`${t('t509')} ${t('t963')} ${scanStatus}`);
        } else if (scanStatus > DEVICE_SCAN_STATUS.IONET_VERIFY_MIN) {
          setScanStatus(
            `${t('t510')} ${t('t963')} ${
              scanStatus - DEVICE_SCAN_STATUS.IONET_VERIFY_MIN
            }`,
          );
        }
        if (scanStatus === DEVICE_SCAN_STATUS.IONET_NORM) {
          const rescannedPageData: DiscoveryPageData = !isHvac
            ? await fetchDiscoveryDataByUnit([
                xmlBackendURL,
                user,
                t,
                isOfflineProgramming,
                unit,
              ])
            : await fetchHvacDiscoveryDataByUnit([
                xmlBackendURL,
                user,
                t,
                isOfflineProgramming,
                unit,
              ]);
          onRescanComplete(rescannedPageData);
          onClose();
          Notification.open({
            message: t('t3123'),
            description: t('t3350'),
            duration: 3,
            type: 'success',
            theme,
            testId: 'discoveryFlow-rescan-notification',
          });
        }
      }
      if (+scanStatusResponse?.scan_status !== DEVICE_SCAN_STATUS.IONET_NORM) {
        await delay(10000);
        await checkScanStatusAndFetchPageData();
      }
    } catch (e) {
      onClose();
      if (isXmlError(e)) {
        Notification.error({
          message: t('t3348'),
          description: e.displayError(t),
          duration: 3,
          theme,
          testId: 'discoveryFlow-error-notification',
        });
      }
    }
  };

  React.useEffect(() => {
    const checkRescanStatus = async () => {
      setIsScanning(true);
      await checkScanStatusAndFetchPageData();
    };
    if (
      rescanResponse &&
      (+rescanResponse?.scanning === DEVICE_SCAN_STATUS.SCANNING ||
        !rescanResponse?.scanning) &&
      scanStatus === t('t3345')
    ) {
      checkRescanStatus();
    }
  }, [rescanResponse]);

  return (
    <>
      <Modal
        styles={{
          contentContainer: {
            minWidth: [100, 300],
          },
        }}
        isOpen={isOpen}
        onClose={onClose}
        shouldCloseOnOverlayClick={!isScanning}
        testId="discoveryFlow-rescan-modal"
        header={
          <Modal.Header
            testId="discoveryFlow-rescan-modal-header"
            title={!discoveryTableData?.length ? t('t768') : t('t512')}
            showCloseButton={false}
          />
        }
      >
        <Spinner size={SpinnerSize.small} styles={{ root: { mb: '10px' } }} />
        <Div testId="rescan-status" style={{ textAlign: 'center' }}>
          {scanStatus}
        </Div>
      </Modal>
    </>
  );
};
