import React from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import {
  ConfigurationListItem,
  XML_REFRIG_COPY_TAB_INDEX,
  fetchLayoutListByUnit,
} from '@danfoss/etui-sm-xml';
import { useAuth } from '@danfoss/etui-sm';
import { useTheme } from '@danfoss/etui-system';
import { Notification } from '@danfoss/etui-core';
import {
  ParamTableData,
  ParametersTableContent,
} from '../../types/RefrigCopy.types';
import { useRefrigCopy } from '../../context';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { writeConfigurationListItem } from '../../../../../Configuration';
import { REFRIG_SUCCESS_RESPONSE } from '../../../RefrigLayoutModal/types';
import { ParametersFlowPage } from './ParametersFlowPage';
import {
  filterParamTableContentList,
  processParametersTableData,
} from './actions';

interface RefrigCopyParameterFlowContentProps {
  tableAddress: string;
}

export const ParametersFlowContent = ({
  tableAddress,
}: RefrigCopyParameterFlowContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuth();

  const { unit, currentUnitUrl: url } = useRefrigCopy();

  const {
    paramTableData,
    setMultipage,
    searchMode,
    isAllPageDataFetched,
    page,
    pageValues,
    searchValue,
    allPageData,
    setParamTableData,
    setAllPageData,
    setIsAllPageDataFetched,
    setIsPageDataFetched,
  } = useRefrigCopy();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const networkTableData = await fetchLayoutListByUnit([
          url,
          unit,
          tableAddress,
          XML_REFRIG_COPY_TAB_INDEX.PARAMETERS,
          user,
          page,
        ]);
        if (networkTableData?.items?.l?.length) {
          const paramTableContentList: ParametersTableContent[] =
            processParametersTableData(networkTableData as any);
          setMultipage(networkTableData?.multipage);
          setParamTableData({ paramTableContent: paramTableContentList });
          setIsPageDataFetched(true);
        }
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3403'),
          duration: 3,
          theme,
          testId: 'parameterFlow-content-notification',
        });
      }
    };
    if (!paramTableData?.paramTableContent?.length) {
      setIsPageDataFetched(false);
      fetch();
    } else {
      fetch();
    }
  }, [page]);

  const handleOnSelect = async (listItem: ConfigurationListItem) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_COPY_TAB_INDEX.PARAMETERS}`,
      null,
      '0',
      '0',
      listItem,
      listItem.fVal,
      listItem.iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      revalidate();
    }
  };

  const pages = searchMode ? pageValues : [];

  const fetchAllPages = async (pages: number[]) => {
    const response = [];
    await pages.reduce(async (promise, page) => {
      await promise;
      page -= 1;
      const data = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.PARAMETERS,
        user,
        page.toString(),
      ]);
      response.push(data);
    }, Promise.resolve());
    const allPageResponse = response.filter(data => data !== undefined).flat();
    return allPageResponse;
  };

  const { data, isLoading: isAllPagesDataLoading } = useSWR(
    pages,
    pages?.length && !allPageData?.length ? fetchAllPages : null,
  );
  if (data?.length && !allPageData?.length && !isAllPagesDataLoading) {
    let allContentList: ParametersTableContent[] = [];
    allContentList = processParametersTableData(data);

    const filteredParamTableContentList = filterParamTableContentList(
      allContentList,
      searchValue,
    ) as ParametersTableContent[];
    const filteredParamTableData: ParamTableData = {
      paramTableContent: filteredParamTableContentList,
    };
    setIsAllPageDataFetched(true);
    setAllPageData(allContentList);
    setParamTableData(filteredParamTableData);
  }

  const revalidate = async () => {
    if (searchValue) {
      const result = await fetchAllPages(pages);
      let allContentList: ParametersTableContent[] = [];
      allContentList = processParametersTableData(result);
      const filterParamsTableContentList = filterParamTableContentList(
        allContentList,
        searchValue,
      ) as ParametersTableContent[];
      const filteredParamTableData: ParamTableData = {
        paramTableContent: filterParamsTableContentList,
      };
      setIsAllPageDataFetched(true);
      setAllPageData(allContentList);
      setParamTableData(filteredParamTableData);
    } else {
      const tableList = await fetchLayoutListByUnit([
        url,
        unit,
        tableAddress,
        XML_REFRIG_COPY_TAB_INDEX.PARAMETERS,
        user,
        page.toString(),
      ]);
      if (tableList?.items?.l) {
        const paramTableContentList: ParametersTableContent[] =
          processParametersTableData(tableList as any);
        const updatedAlarmTableData: any = {
          paramTableContent: paramTableContentList,
        };
        setParamTableData(updatedAlarmTableData);
      }
    }
  };

  React.useEffect(() => {
    if (searchMode && isAllPageDataFetched) {
      const filteredParamTableContentList = filterParamTableContentList(
        allPageData,
        searchValue,
      ) as ParametersTableContent[];
      const filteredparamTableData: ParamTableData = {
        paramTableContent: filteredParamTableContentList,
      };
      setParamTableData(filteredparamTableData);
    }
  }, [searchValue]);
  return <ParametersFlowPage handleOnSelect={handleOnSelect} />;
};
