import { Div } from '@danfoss/etui-system-elements';
import {
  ContainerDimensions,
  Notification,
  NumberInput,
  SelectInput,
  SelectInputOption,
  Spinner,
  SpinnerSize,
  Table,
  TextInput,
} from '@danfoss/etui-core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MODEL_IDX, XML_DEVICE_LIST } from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import { EmptyState } from '@danfoss/etui-sm';
import {
  CircuitsTableData,
  DEVICE_STATUS,
  DEVICE_TYPE,
  DiscoveryTableData,
} from '../../types/DiscoveryPage.types';
import {
  getCircuitDeviceIdBasedOnIndex,
  getCircuitModelSelected,
  getIsMonitorOrSubcool,
  getTypeDescription,
  hasSpecialCharacters,
  isAddressDisabled,
  isCircuitConfigured,
  isModelDisabled,
  verifyAddres,
} from '../../utils';
import { getGenericCount } from '../../utils/get-generic-count';
import { LayoutDropDownMenu } from '../../../LayoutDropDownMenu';
import { useRefrigLayout } from '../../context';
import { CircuitsFlowAddCircuit } from './CircuitsFlowAddCircuit';

export interface CircuitsTableProps {
  filterCircuits: () => void;
}

export const CircuitsTable = ({ filterCircuits }: CircuitsTableProps) => {
  const { t } = useTranslation();

  const {
    deviceConstraintsData,
    discoveryTableData,
    caseTypes,
    circuitTypeModelList,
    circuitsTableData,
    setIsPackRemoveOperation,
  } = useRefrigLayout();

  const [circuitsData, setCircuitsData] = React.useState([]);
  const [circuitsNames, setCircuitNames] = React.useState<string[]>([]);
  const [circuitsAddress, setCircuitsAddress] = React.useState<string[]>([]);
  const theme = useTheme();

  const handleCopy = (copyAmount: number, setSelectedRowDeviceId: number) => {
    const deviceIdToClone =
      setSelectedRowDeviceId === null
        ? getCircuitDeviceIdBasedOnIndex(discoveryTableData, 0)
        : setSelectedRowDeviceId;
    const selectedCircuit = discoveryTableData.find(
      device => device.deviceId === deviceIdToClone,
    );

    cloneAndSetCircuit(selectedCircuit, copyAmount);
  };

  const cloneAndSetCircuit = (
    circuit: DiscoveryTableData,
    copyAmount: number,
  ) => {
    for (let i = 1; i <= copyAmount; i++) {
      if (
        getGenericCount(discoveryTableData) >=
        Number(deviceConstraintsData.max_octl)
      ) {
        break;
      }
      const newDimIndex = Number(circuit.dim2) + i;
      const newDeviceId =
        Math.max(...discoveryTableData.map(item => item.deviceId)) + 1;
      const clonedCircuit: DiscoveryTableData = {
        newDevice: true,
        deviceId: newDeviceId,
        category: circuit.category,
        deviceType: circuit.deviceType,
        type: circuit.type,
        name:
          circuit.name !== ''
            ? `${circuit.name} ${newDimIndex.toString()}`
            : circuit.name + t('t481') + newDimIndex.toString(),
        model: circuit.model,
        removed: false,
        file: circuit.file,
        version: circuit.version,
        listname: circuit.listname,
        model_idx: circuit.model_idx,
        ip: '',
        address: circuit.address !== '-' ? '0' : '-',
        rk: 255,
        sg: 255,
        dim1: '0',
        dim2: newDimIndex.toString(),
        dim3: '0',
        num_sg: 0,
        cfg: '0',
        online: '0',
        status: DEVICE_STATUS.RED,
        circ_type: circuit.circ_type,
        is_monitor: circuit.is_monitor,
        subcool: circuit.subcool,
        sc: circuit.sc,
        case_type: caseTypes.at(0),
        code: circuit.code,
        deviceBus: circuit.deviceBus,
        mx_naddr: circuit.mx_naddr,
      };

      discoveryTableData.push(clonedCircuit);
    }

    filterCircuits();
  };

  const handleDelete = (selectedDeviceId: number) => {
    const circuitIndexToRemove = discoveryTableData.findIndex(
      device => device.deviceId === selectedDeviceId,
    );
    discoveryTableData.splice(circuitIndexToRemove, 1);

    filterCircuits();
    setIsPackRemoveOperation(true);
  };

  const circuitDeviceListOptions: SelectInputOption[] =
    circuitTypeModelList?.modelList.map(value => ({
      value: value._,
      label: value._,
    }));

  const handleModelChange = (
    event: SelectInputOption,
    circuits: DiscoveryTableData,
  ) => {
    const { value: selectedValue } = event;
    const { deviceId: selectedDeviceId } = circuits;
    const idxCombo: number = circuitDeviceListOptions.findIndex(
      options => options.value === selectedValue,
    );
    const selectedModel = circuitDeviceListOptions.at(idxCombo).value;
    if (idxCombo < 0) {
      return;
    }
    const model = selectedModel.split(' ');
    for (const device of discoveryTableData) {
      if (
        device.deviceId === selectedDeviceId &&
        (device.deviceType === DEVICE_TYPE.GEN_CIRCUIT ||
          device.deviceType === DEVICE_TYPE.CIRCUIT)
      ) {
        if (idxCombo < Number(deviceConstraintsData.gen_ofs_ct)) {
          device.deviceType = DEVICE_TYPE.CIRCUIT;
          device.address = '-';
          device.version = '';
          device.device = '';
        } else {
          if (device.deviceType !== DEVICE_TYPE.GEN_CIRCUIT) {
            device.address = '0';
          }
          device.deviceType = DEVICE_TYPE.GEN_CIRCUIT;
        }
        device.type = getTypeDescription(device.deviceType, t);
        device.file = '';
        device.version = '';
        device.model = model.at(0);
        if (device.model === circuitTypeModelList.modelList.at(0)._) {
          for (const childDevice of discoveryTableData) {
            if (childDevice.deviceId !== device.deviceId) {
              if (device.listname === circuitTypeModelList.modelList.at(0)._) {
                device.listname = circuitTypeModelList.modelList.at(1)._;
              }
            }
          }
        }
        device.listname = selectedValue;
        device.code = model.at(1);
        device.model_idx = idxCombo + 1;
        device.is_monitor = getIsMonitorOrSubcool(
          idxCombo,
          MODEL_IDX.IDX_MONITORING,
        );
        device.subcool = getIsMonitorOrSubcool(idxCombo, MODEL_IDX.IDX_SUBCOOL);
        break;
      }
    }
    filterCircuits();
  };

  const saveName = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value, accept } = event.target;
    discoveryTableData.find(
      device =>
        (device.deviceType === DEVICE_TYPE.CIRCUIT ||
          device.deviceType === DEVICE_TYPE.GEN_CIRCUIT) &&
        device.deviceId === Number(accept),
    ).name = value;
    filterCircuits();
  };

  const saveAddress = (address: string, accept: string) => {
    const invalidMessage = verifyAddres(
      address,
      accept,
      discoveryTableData,
      Number(deviceConstraintsData.max_naddr),
    );
    if (invalidMessage) {
      Notification.error({
        message: t('t17'),
        description:
          invalidMessage === 't3520'
            ? t(invalidMessage, { maxAddr: deviceConstraintsData.max_naddr })
            : t(invalidMessage),
        duration: 3,
        theme,
        testId: 'circuitTable-saveAddress-notification',
      });
      discoveryTableData.find(
        device => device.deviceId.toString() === accept,
      ).address = '0';
    } else if (address !== '' && !hasSpecialCharacters(address)) {
      discoveryTableData.find(
        device =>
          (device.deviceType === DEVICE_TYPE.CIRCUIT ||
            device.deviceType === DEVICE_TYPE.GEN_CIRCUIT) &&
          device.deviceId === Number(accept),
      ).address = address;
    } else {
      discoveryTableData.find(
        device =>
          (device.deviceType === DEVICE_TYPE.CIRCUIT ||
            device.deviceType === DEVICE_TYPE.GEN_CIRCUIT) &&
          device.deviceId === Number(accept),
      ).address = '0';
    }
    filterCircuits();
  };

  const getCircuitTableDataItem = (
    circuits: DiscoveryTableData,
    tableIndex: number,
  ): CircuitsTableData => {
    return {
      id: tableIndex,
      name: (
        <Div id={`name-div-${tableIndex}`} width="80%">
          <TextInput
            id={`name-text-${tableIndex}`}
            testId={`circuitName-${tableIndex ?? ''}-input`}
            defaultValue={circuitsNames[tableIndex - 1]}
            key={circuitsNames[tableIndex - 1]}
            accept={circuits.deviceId.toString()}
            maxLength={Number(deviceConstraintsData.max_name)}
            type="text"
            aria-label=""
            width="100%"
            height="auto"
            onBlur={event => {
              saveName(event);
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
          />
        </Div>
      ),

      deviceId: circuits.deviceId,
      address: (
        <Div testId="address-div" width="40px" minWidth="60%">
          <NumberInput
            id={`address-${tableIndex}`}
            testId={`circuitTable-${tableIndex ?? ''}-numberInput`}
            name={`address-${tableIndex}`}
            accept={circuits.deviceId.toString()}
            type="text"
            min={0}
            max={999}
            isDisabled={isAddressDisabled(circuits)}
            aria-label=""
            width="100%"
            height="auto"
            onBlur={event => {
              saveAddress(event.target.value, circuits.deviceId.toString());
            }}
            mb="8px"
            onKeyDown={event => {
              // Prevent letters in the input field
              if (event.keyCode > 64 && event.keyCode < 91) {
                event.preventDefault();
              }
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                e.currentTarget.blur();
              }
            }}
            maxLength={+deviceConstraintsData?.max_naddr.length}
            value={circuitsAddress[tableIndex - 1]}
            key={circuitsAddress[tableIndex - 1]}
          />
        </Div>
      ),
      model: (
        <Div testId="model-div" width="60%" height="90%">
          <SelectInput
            testId="copy-modal-selectInput"
            styles={{
              menuList: { maxHeight: '250px' },
            }}
            value={{ label: circuits?.listname, value: '' }}
            options={circuitDeviceListOptions}
            disabled={isModelDisabled(circuits)}
            onChange={(event: SelectInputOption) => {
              handleModelChange(event, circuits);
            }}
            size="small"
            name="copy modal"
            searchable={true}
          />
        </Div>
      ),
      operation: (
        <Div testId="copy-delete-div" ml="50%">
          <LayoutDropDownMenu
            rowIndex={circuits.deviceId}
            handleOnCopyOk={handleCopy}
            handleDeleteSelectedRow={handleDelete}
            isCircuitScreen={true}
          />
        </Div>
      ),
    };
  };

  const collectTableValues = () => {
    const circuitNames = circuitsTableData.map(circuit => circuit.name);
    const circuitAddresses = circuitsTableData.map(circuit => circuit.address);
    setCircuitNames(circuitNames);
    setCircuitsAddress(circuitAddresses);
    updateCircuitsTable(circuitsData);
  };

  const updateCircuitsTable = (filteredCircuitsData: DiscoveryTableData[]) => {
    const circuitTableData: CircuitsTableData[] = [];

    filteredCircuitsData?.forEach(
      (circuits: DiscoveryTableData, index: number) => {
        circuits.listname = getCircuitModelSelected(
          circuits,
          circuitTypeModelList,
        );
        circuitTableData.push(getCircuitTableDataItem(circuits, index + 1));
      },
    );

    setCircuitsData(circuitTableData);
  };

  const handleAddCircuitDevice = () => {
    isCircuitConfigured(discoveryTableData)
      ? handleCopy(1, null)
      : addCircuits();
  };

  const addCircuits = () => {
    if (
      getGenericCount(discoveryTableData) >=
      Number(deviceConstraintsData.max_octl)
    ) {
      return;
    }
    const newDeviceId =
      Math.max(...discoveryTableData.map(item => item.deviceId)) + 1;

    const newCircuit: DiscoveryTableData = {
      newDevice: true,
      deviceId: newDeviceId,
      category: XML_DEVICE_LIST.DEV_TYPE_EVAP,
      deviceType: DEVICE_TYPE.CIRCUIT,
      type: getTypeDescription(DEVICE_TYPE.CIRCUIT, t),
      name: t('t481'),
      model: t('t511'),
      file: '',
      version: '',
      code: '',
      listname: circuitTypeModelList?.modelList.at(1)._,
      model_idx: 0,
      ip: '',
      address: '-',
      rk: 0,
      sg: 0,
      dim1: '0',
      dim2: '0',
      dim3: '0',
      num_sg: 0,
      cfg: '0',
      online: '0',
      status: DEVICE_STATUS.RED,
      circ_type: '0',
      is_monitor: '0',
      subcool: '0',
      sc: '0',
      case_type: caseTypes.at(0),
      deviceBus: t('t511'),
      mx_naddr: deviceConstraintsData.max_naddr,
    };

    discoveryTableData.push(newCircuit);

    filterCircuits();
  };

  const columns = [
    {
      title: t('t76'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: t('t57'),
      dataIndex: 'address',
      key: 'address',
      width: '10%',
    },
    {
      title: t('t355'),
      dataIndex: 'model',
      key: 'model',
      width: '50%',
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      width: '10%',
    },
  ];

  const handleCircuitDataChange = () => {
    circuitsTableData?.length ? collectTableValues() : setCircuitsData([]);
  };

  React.useEffect(() => {
    if (circuitTypeModelList?.modelList?.length) {
      filterCircuits();
    }
  }, [circuitTypeModelList?.modelList?.length]);

  React.useMemo(() => {
    updateCircuitsTable(circuitsTableData);
  }, [circuitsAddress, circuitsNames]);

  React.useEffect(() => {
    handleCircuitDataChange();
  }, [circuitsTableData]);

  return (
    <ContainerDimensions>
      {() =>
        circuitTypeModelList && circuitsData.length ? (
          <>
            <Div
              testId="circuits-flow-table-content-div"
              style={{ marginTop: '20px' }}
            >
              <Table
                testId="circuits-table"
                rowKey="circuits-table-content"
                columns={columns}
                dataSource={circuitsData}
                pagination={false}
                styles={{ root: { overflow: 'visible' } }}
              />
              <CircuitsFlowAddCircuit
                handleOnAddCircuit={handleAddCircuitDevice}
              />
            </Div>
          </>
        ) : !circuitTypeModelList ? (
          <Div
            testId="circuit-flow-table-spinner-div"
            style={{ marginTop: '200px' }}
          >
            <Spinner size={SpinnerSize.small} />
          </Div>
        ) : (
          <Div testId="circuits-flow-table-empty-div">
            <EmptyState title={t('t3351')} />
            <CircuitsFlowAddCircuit
              handleOnAddCircuit={handleAddCircuitDevice}
            />
          </Div>
        )
      }
    </ContainerDimensions>
  );
};
