import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import { color } from 'utils/color';
import { Measurement, useAuth, useMeasurementUnits } from '@danfoss/etui-sm';
import { HistoryCfg } from '@danfoss/etui-sm-xml';
import { getHistoryUnitsConfig } from 'utils/get-history-units-config';
import { useTheme } from '@danfoss/etui-system';
import { Notification, TreeItem } from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import {
  ChartData,
  DateRange,
  HistoryConfig,
  HistoryConfigData,
  LegendMap,
} from './HistoryPage.types';
import {
  runChecksForQueries,
  startHistoryQueries,
} from './utils/history-actions';
import { processData } from './utils/history-parsing';

export interface HistoryContextProps {
  chartData: ChartData[] | null;
  dateRange: DateRange;
  fetchHistory: (selected: HistoryCfg[]) => void;
  formats: Measurement;
  loadHistoryFromHSTFile: (file: any) => void; // TODO: Add logic to parse HST files
  loading: boolean;
  setLoading: (loading: boolean) => void;
  loadProgress: number;
  setLoadProgress: (loadProgress: number) => void;
  parameterMetadata: LegendMap;
  setChartData: (data: ChartData[] | null) => void;
  setDateRange: (dateRange: DateRange) => void;
  setFormats: React.Dispatch<React.SetStateAction<Measurement>>;
  setParameterMetadata: (value: React.SetStateAction<LegendMap>) => void;
  openCloseHistorySections: string[];
  setOpenCloseHistorySections: (openCloseHistorySections: string[]) => void;
  historyCheckedItems: string[];
  setHistoryCheckedItems: (historyCheckedItems: string[]) => void;
  isHistoryImport: boolean;
  setIsHistoryImport: (isHistoryImport: boolean) => void;
  historyImportChartData: ChartData[] | null;
  setHistoryImportChartData: (
    historyImportChartData: ChartData[] | null,
  ) => void;
  historyImportParameterMetadata: LegendMap;
  setHistoryImportParameterMetadata: (
    historyImportParameterMetadata: LegendMap,
  ) => void;
  openCloseHistoryImportSections: string[];
  setOpenCloseHistoryImportSections: (
    openCloseHistoryImportSections: string[],
  ) => void;
  historyImportCheckedItems: string[];
  setHistoryImportCheckedItems: (historyImportCheckedItems: string[]) => void;
  historyImportDateRange: DateRange;
  setHistoryImportDateRange: (historyImportDateRange: DateRange) => void;
  historyImportConfigs: HistoryConfig[];
  setHistoryImportConfigs: (historyImportConfigs: HistoryConfig[]) => void;
  isHistoryDataPointsLoading: boolean;
  setIsHistoryDataPointsLoading: (isHistoryDataPointsLoading: boolean) => void;
  historyRawData: ArrayBuffer[];
  setHistoryRawData: (historyRawData: ArrayBuffer[]) => void;
  historyImportRawData: ArrayBuffer[];
  setHistoryImportRawData: (historyRawData: ArrayBuffer[]) => void;
  historySelectedItemsToDraw: string[];
  setHistorySelectedItemsToDraw: (historySelectedItemsToDraw: string[]) => void;
  historyImportSelectedItemsToDraw: string[];
  setHistoryImportSelectedItemsToDraw: (
    historyImportSelectedItemsToDraw: string[],
  ) => void;
  isHistoryLeftPanelOpen: boolean;
  setIsHistoryLeftPanelOpen: (isHistoryLeftPanelOpen: boolean) => void;
  isHistoryImportLeftPanelOpen: boolean;
  setIsHistoryImportLeftPanelOpen: (isHistoryLeftPanelOpen: boolean) => void;
  historyTreeData: TreeItem[];
  setHistoryTreeData: (historyTreeData: TreeItem[]) => void;
  historyConfigData: HistoryConfigData;
  setHistoryConfigData: (historyConfigData: HistoryConfigData) => void;
}

function getInitDateRange(start: string, end: string) {
  if (start && end) {
    return { startDate: new Date(+start), endDate: new Date(+end) };
  }

  const date = new Date();
  date.setHours(date.getHours() - 12);
  const twelveHoursAgo = date;
  return {
    startDate: twelveHoursAgo,
    endDate: new Date(),
  };
}

// Create the history context
export const HistoryContext = createContext<HistoryContextProps>(null);

// Create a custom hook to access the history context
export const useHistoryContext = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error(
      'useHistoryContext must be used within a HistoryContextProvider',
    );
  }
  return context;
};

const START_TIME = 's';
const END_TIME = 'e';

const HistoryContextProvider: React.FC = ({ children }) => {
  const urlDateRange = new URLSearchParams(useLocation().search);
  const { user } = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();

  const [chartData, setChartData] = React.useState<any[]>(null);
  const [dateRange, setDateRange] = React.useState<DateRange>(
    getInitDateRange(urlDateRange.get(START_TIME), urlDateRange.get(END_TIME)),
  );
  const [loadProgress, setLoadProgress] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formats, setFormats] = React.useState<Measurement>(null);

  const [parameterMetadata, setParameterMetadata] = React.useState<LegendMap>(
    {},
  );
  const [openCloseHistorySections, setOpenCloseHistorySections] =
    React.useState<string[]>([]);
  const [historyCheckedItems, setHistoryCheckedItems] = React.useState<
    string[]
  >([]);
  const [isHistoryImport, setIsHistoryImport] = React.useState<boolean>(false);
  const [historyImportChartData, setHistoryImportChartData] =
    React.useState<any[]>(null);
  const [historyImportParameterMetadata, setHistoryImportParameterMetadata] =
    React.useState<LegendMap>({});
  const [openCloseHistoryImportSections, setOpenCloseHistoryImportSections] =
    React.useState<string[]>([]);
  const [historyImportCheckedItems, setHistoryImportCheckedItems] =
    React.useState<string[]>([]);
  const [historyImportDateRange, setHistoryImportDateRange] =
    React.useState<DateRange>(
      getInitDateRange(
        urlDateRange.get(START_TIME),
        urlDateRange.get(END_TIME),
      ),
    );
  const [historyImportConfigs, setHistoryImportConfigs] =
    React.useState<HistoryConfig[]>(null);
  const [isHistoryDataPointsLoading, setIsHistoryDataPointsLoading] =
    React.useState<boolean>(true);
  const [historyRawData, setHistoryRawData] = React.useState<ArrayBuffer[]>([]);
  const [historyImportRawData, setHistoryImportRawData] = React.useState<
    ArrayBuffer[]
  >([]);
  const [historySelectedItemsToDraw, setHistorySelectedItemsToDraw] =
    React.useState<string[]>([]);
  const [
    historyImportSelectedItemsToDraw,
    setHistoryImportSelectedItemsToDraw,
  ] = React.useState<string[]>([]);
  const [isHistoryLeftPanelOpen, setIsHistoryLeftPanelOpen] =
    React.useState(true);
  const [isHistoryImportLeftPanelOpen, setIsHistoryImportLeftPanelOpen] =
    React.useState(true);
  const [historyTreeData, setHistoryTreeData] =
    React.useState<TreeItem[]>(null);
  const [historyConfigData, setHistoryConfigData] =
    React.useState<HistoryConfigData>(null);

  const measurements = useMeasurementUnits();
  useEffect(() => {
    measurements && setFormats(measurements);
  }, [measurements]);

  const fetchHistory = async (selected: HistoryCfg[]) => {
    setChartData(null);
    setLoadProgress(0);

    const { mSecsOffset255toPC } = selected[0]; // TODO different units might have different offsets!
    const { startDate, endDate } = dateRange;

    try {
      setLoading(true);
      const queries = await startHistoryQueries(
        user,
        Math.round((+startDate + mSecsOffset255toPC) / 1e3),
        Math.round((+endDate + mSecsOffset255toPC) / 1e3),
        selected,
      );
      const rawData = await runChecksForQueries(
        user,
        queries,
        setLoadProgress,
        false, // shouldFetchHistoryByResource,
      );

      const processedData = processData(
        rawData,
        selected,
        getHistoryUnitsConfig(measurements),
        startDate,
      );

      const sortedData = processedData.map(dataSet => {
        const sorted = dataSet.data.slice().sort((a, b) => a.time - b.time);
        return { ...dataSet, data: sorted };
      });

      setChartData(sortedData);

      if (!sortedData?.length) {
        Notification.warning({
          message: t('t3124'),
          description: t('t442'),
          duration: 3,
          theme,
          testId: 'noHistory-notification',
        });
      }
    } catch (e) {
      Notification.error({
        message: t('t17'),
        description: e.message,
        duration: 3,
        theme,
        testId: 'noHistory-error-notification',
      });
      // handleHistoryError(e);
    } finally {
      setLoadProgress(100);
      setLoading(false);
      // setShouldRequestAlarms(true);
    }
  };

  React.useEffect(() => {
    if (chartData === null) return;
    const colors = color();

    const metadata = chartData.reduce(
      (acc, { param }) => ({
        ...acc,
        [param.id]: {
          isHidden: false,
          color: colors.next().value,
        },
      }),
      {},
    );

    setParameterMetadata(metadata);
    return () => {};
  }, [chartData]);

  const loadHistoryFromHSTFile = (_file: any) => {
    throw new Error('Not implemented');
  };

  return (
    <HistoryContext.Provider
      value={{
        chartData,
        dateRange,
        fetchHistory,
        formats,
        loadHistoryFromHSTFile,
        loading,
        loadProgress,
        parameterMetadata,
        setChartData,
        setDateRange,
        setFormats,
        setParameterMetadata,
        setLoading,
        setLoadProgress,
        openCloseHistorySections,
        setOpenCloseHistorySections,
        historyCheckedItems,
        setHistoryCheckedItems,
        isHistoryImport,
        setIsHistoryImport,
        historyImportChartData,
        setHistoryImportChartData,
        historyImportParameterMetadata,
        setHistoryImportParameterMetadata,
        openCloseHistoryImportSections,
        setOpenCloseHistoryImportSections,
        historyImportCheckedItems,
        setHistoryImportCheckedItems,
        historyImportDateRange,
        setHistoryImportDateRange,
        historyImportConfigs,
        setHistoryImportConfigs,
        isHistoryDataPointsLoading,
        setIsHistoryDataPointsLoading,
        historyRawData,
        setHistoryRawData,
        historyImportRawData,
        setHistoryImportRawData,
        historySelectedItemsToDraw,
        setHistorySelectedItemsToDraw,
        historyImportSelectedItemsToDraw,
        setHistoryImportSelectedItemsToDraw,
        isHistoryLeftPanelOpen,
        setIsHistoryLeftPanelOpen,
        isHistoryImportLeftPanelOpen,
        setIsHistoryImportLeftPanelOpen,
        historyTreeData,
        setHistoryTreeData,
        historyConfigData,
        setHistoryConfigData,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryContextProvider;
