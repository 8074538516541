import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { TreeView, SelectedValueType, TreeItem } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { EmptyState } from '@danfoss/etui-sm/components';
import { Skeletons } from 'components/Skeleton';
import { useDebounce } from 'hooks';
import { TreeViewSearchInput } from 'components/TreeViewSearchInput';
import { defineOpenSections } from 'utils';
import { setParentDeviceFields } from '../../pages/HistoryPage/utils/history-utils';
import { getOpenDeviceItemIdsById } from './utils';
import { DeviceBasedMenuItemProps } from './DeviceBasedMenuItem';

export function DeviceTreeView({
  enableFilter: filter,
  data,
  type,
  onMenuItemClick,
  getChildrenToCheck,
  checkedItems,
  setCheckedItems,
  rowTemplate,
  selectedRow,
  defaultOpenDevice,
  isFilterDisabled,
}: DeviceBasedMenuItemProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [filterValue, setFilterValue] = React.useState('');
  const [matchedData, setFilteredData] = React.useState<TreeItem[]>([]);

  const [openDeviceItemIds, setOpenDeviceItemIds] = React.useState<string[]>(
    getOpenDeviceItemIdsById(defaultOpenDevice),
  );

  const handleFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    setFilterValue(searchString);
  };

  const handleOpenSectionChange = (ids: SelectedValueType[]) => {
    setOpenDeviceItemIds(ids);
  };

  const debouncedSearchTerm = useDebounce(filterValue.toLocaleLowerCase(), 500);

  React.useEffect(() => {
    if (debouncedSearchTerm && data) {
      const groupedDevices = data[type];
      // eslint-disable-next-line func-names
      const filterCallback = function (device) {
        if (
          device.name.toLocaleLowerCase().replace(/\s\s+/g, ' ').includes(this)
        ) {
          return true;
        }

        if (device.items && device.items.length) {
          device.items = device.items.filter(filterCallback.bind(this));
        }

        return device.items && device.items.length > 0;
      };

      const filterMenu = search =>
        JSON.parse(JSON.stringify(groupedDevices)).filter(
          filterCallback.bind(search),
        );

      const filteredData = filterMenu(debouncedSearchTerm);
      setFilteredData(filteredData);
    }
  }, [debouncedSearchTerm, data]);

  const initialData = (data?.[type] as TreeItem[]) || [];
  const treeData =
    filter && debouncedSearchTerm
      ? matchedData
      : setParentDeviceFields(initialData);
  const isLoading = !data;

  React.useEffect(() => {
    if (!debouncedSearchTerm) {
      setOpenDeviceItemIds([]);
    } else {
      const ids = defineOpenSections(treeData);
      setOpenDeviceItemIds(ids);
    }
  }, [treeData.length]);

  return (
    <>
      {filter && (
        <Div testId="equipment-treeview-search-input">
          <TreeViewSearchInput
            testId="device-treeViewSearchInput"
            disabled={isFilterDisabled}
            name="treeFilter"
            value={filterValue}
            label={t('t600')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleFilterValueChange(e)
            }
          />
        </Div>
      )}
      <TreeView
        testId="device-treeView"
        data={treeData}
        openSections={openDeviceItemIds}
        onOpenSectionChange={handleOpenSectionChange}
        onRowSelect={(...args) => onMenuItemClick?.(...args)}
        getChildrenToCheck={getChildrenToCheck}
        checkedItems={checkedItems}
        onCheckedItemsChange={setCheckedItems}
        showCheckBox={Boolean(checkedItems)}
        rowTemplate={rowTemplate}
        selectedRow={selectedRow}
        emptyPlaceholder={
          isLoading ? (
            <Skeletons
              times={8}
              height={`${40 - 16}px`}
              width={`calc(100% - ${theme.spacing.md}px)`}
              m={`${theme.spacing.xs}px auto`}
              opacity={0.5}
            />
          ) : (
            <EmptyState
              title={t('t916')}
              size="small"
              styles={{ root: { p: theme.spacing.md } }}
            />
          )
        }
        styles={{
          root: { p: 0 },
          row: {
            root: { fontWeight: theme.typography.fontWeightBold },
          },
        }}
      />
    </>
  );
}
