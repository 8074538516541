import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginLayout } from 'components/LoginLayout';
import { useTheme } from '@danfoss/etui-system';
import {
  Alert,
  Button,
  H5,
  Icon,
  icons,
  Notification,
  useResponsive,
} from '@danfoss/etui-core';
import { P, Span } from '@danfoss/etui-system-elements';
import { routePaths } from 'routePaths';
import { useHistory } from 'react-router-dom';
import { logoutUser, useAuth } from '@danfoss/etui-sm';
import {
  getMetadataUnitUrlsWithPortNumbers,
  SecurityMode,
} from '@danfoss/etui-sm-xml';
import { SECURITY_URL } from '../../config';
import { getSecurityConfigList, setSecurityMode, initChanges } from './actions';
import * as S from './styles';

const SECURITY_TOKEN = 'Security_Enf_Level';
const COMPATIBLE_MODE_OPTION_VALUE = '0';
const STRICT_MODE_OPTION_VALUE = '2';
const NOTIFICATION_SHOWN_SECONDS = 5;

const SecurityCompliancePage = () => {
  const { replace } = useHistory();
  const { user, dispatch } = useAuth();

  if (!user) replace(routePaths.pages.login);

  const theme = useTheme();
  const { t } = useTranslation();

  const { screenIsAtMost } = useResponsive({
    md: parseInt(theme.breakpoints[1], 10),
  });
  const isMdView = screenIsAtMost('md');

  const [isBusy, setIsBusy] = React.useState(false);

  const readSecurityInfo = () => window.open(SECURITY_URL, '_blank');

  const selectSecurityMode = async (mode: SecurityMode) => {
    setIsBusy(true);
    const isCompatible = mode === SecurityMode.COMPATIBLE;
    const unitUrls = getMetadataUnitUrlsWithPortNumbers();

    const value = isCompatible
      ? COMPATIBLE_MODE_OPTION_VALUE
      : STRICT_MODE_OPTION_VALUE;

    try {
      await Promise.all(
        unitUrls.map(async unitUrl => {
          const url = `${unitUrl}/xml.cgi`;
          const securityConfigList = await getSecurityConfigList(url);
          const initializeButton = securityConfigList[0];
          const securityLevelItem = securityConfigList.find(({ token }) =>
            token.includes(SECURITY_TOKEN),
          );
          await setSecurityMode(url, securityLevelItem, value);
          await initChanges(url, initializeButton);
        }),
      );

      await logoutUser(dispatch);
    } catch (e) {
      Notification.error({
        theme,
        message: t('t900'),
        description: e.message,
        duration: NOTIFICATION_SHOWN_SECONDS,
        testId: 'security-compliance-notification',
      });
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <LoginLayout>
      <S.TextContainer isMdView={isMdView}>
        <H5
          testId="securityCompliance-ITInfo-header"
          fontWeight={theme.typography.fontWeightBold}
        >
          {t('t3668')}
        </H5>
        <P mt={`${theme.spacing.xs}px !important`}>{t('t3669')}</P>
        <P mt={`${theme.spacing.xs}px !important`}>{t('t3670')}</P>
        <P mt={`${theme.spacing.xs}px !important`}>{t('t3671')}</P>
        <Alert
          testId="warning-Alert"
          type="warning"
          message={t('t3675')}
          styles={{
            root: { m: `${theme.spacing.sm}px 0 !important` },
            iconContainer: {
              alignSelf: 'baseline',
              pr: `${theme.spacing.xs}px`,
            },
          }}
        />
        <S.SecurityLink onClick={readSecurityInfo}>
          <Span cursor="pointer">{t('t3672')}</Span>
          <Icon
            glyph={icons.EXTERNAL}
            styles={{
              root: {
                cursor: 'pointer',
                transform: 'translate(4px, -6px)',
              },
            }}
          />
        </S.SecurityLink>
        <S.ButtonContainer>
          <Button
            disabled={isBusy}
            flex="1"
            testId="security-select-compatible-button"
            onClick={() => selectSecurityMode(SecurityMode.COMPATIBLE)}
            small={isMdView}
            variant="tertiary"
          >
            {t('t3673')}
          </Button>
          <Button
            disabled={isBusy}
            flex="1"
            testId="security-select-enforcing-button"
            onClick={() => selectSecurityMode(SecurityMode.ENFORCING)}
            small={isMdView}
            variant="primary"
          >
            {t('t3674')}
          </Button>
        </S.ButtonContainer>
      </S.TextContainer>
    </LoginLayout>
  );
};

export { SecurityCompliancePage };
