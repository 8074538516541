import React from 'react';
import { Spinner, SpinnerSize, Table } from '@danfoss/etui-core/';
import { useTheme } from 'styled-components';
import { Div } from '@danfoss/etui-system-elements/';
import { ContentSpinner, EmptyState, SuccessIcon } from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import { useRefrigCopy } from '../../context';
import { CopyDevicesTable } from '../../types/RefrigCopy.types';

export const CopyFlowTable = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    copyDevicesTableData,
    isCopyDevicesDataFetched,
    setIsCopyDevicesDataFetched,
  } = useRefrigCopy();

  const [copyDevicesTable, setCopyDevicesTable] = React.useState<
    CopyDevicesTable[]
  >([]);

  React.useEffect(() => {
    if (copyDevicesTableData?.copyDevicesTableContent?.length) {
      getTableData();
    }
  }, [copyDevicesTableData]);

  const columns = [
    {
      title: t('t76').toUpperCase(),
      dataIndex: 'name',
      key: 'name',
      width: '15%',
    },
    {
      title: t('t57').toUpperCase(),
      dataIndex: 'address',
      key: 'address',
      width: '15%',
    },
    {
      title: t('t355').toUpperCase(),
      dataIndex: 'model',
      key: 'model',
      width: '15%',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: '15%',
    },
    {
      title: t('t42').toUpperCase(),
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const getTableData = () => {
    const copyDevicesTable: CopyDevicesTable[] = [];
    copyDevicesTableData?.copyDevicesTableContent?.forEach(content => {
      copyDevicesTable.push({
        name: content.name,
        address: content.address,
        model: content.model,
        li: content.li,
        time: content.isCopying ? (
          <Div testId="copy-flow-time-spinner-div" display="inline-block">
            <Spinner size={SpinnerSize.xSmall} />
          </Div>
        ) : (
          <Div testId="copy-flow-time-value-div" display="inline-block">
            {content.status}
          </Div>
        ),
        status: content.isCopying ? (
          <Div testId="copy-flow-status-spinner-div" display="inline-block">
            <Spinner size={SpinnerSize.xSmall} />
          </Div>
        ) : (
          <Div testId="copy-flow-status-value-div" mt="-15px">
            <SuccessIcon show={true} />
          </Div>
        ),
      });
    });
    setCopyDevicesTable(copyDevicesTable);
    setIsCopyDevicesDataFetched(true);
  };

  return (
    <Div testId="copy-flow-table-content-div" style={{ marginTop: '20px' }}>
      {!isCopyDevicesDataFetched ? (
        <Div
          testId="copy-flow-table-spinner-div"
          style={{ marginTop: '200px' }}
        >
          <ContentSpinner size={SpinnerSize.small} transparent={true} />
        </Div>
      ) : !copyDevicesTableData?.copyDevicesTableContent?.length ? (
        <Div testId="copy-flow-table-empty-div" style={{ marginTop: '200px' }}>
          <EmptyState title={t('t3424')} />
        </Div>
      ) : (
        <Table
          testId="copy-flow-table"
          rowKey="copy-flow-table-content"
          columns={columns}
          dataSource={copyDevicesTable}
          pagination={false}
          emptyPlaceholder={null}
          styles={{
            td: { p: `${theme.spacing.sm}px`, height: 10 },
            root: { overflow: 'visible' },
          }}
        />
      )}
    </Div>
  );
};
