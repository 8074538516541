import * as React from 'react';
import { Notification } from '@danfoss/etui-core';
import { useAuth, useUnit, useXmlResource } from '@danfoss/etui-sm';
import {
  ConfigurationListItem,
  XML_REFRIG_LAYOUT_TAB_INDEX,
  fetchLayoutListByUnit,
  isXmlError,
} from '@danfoss/etui-sm-xml';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import useSWR from 'swr';
import {
  getArray,
  writeConfigurationListItem,
} from '../../../../../Configuration';
import {
  REFRIG_SUCCESS_RESPONSE,
  PI200TableContent,
} from '../../types/RefrigLayout.types';
import { useRefrigLayout } from '../../context';
import { withErrorHandled } from '../../../../../Configuration/utils/with-error-handled';
import { PI200FlowPage } from './PI200FlowPage';
import { processPI200TableData } from './actions';

interface PI200FlowContentProps {
  tableAddress: string;
}
export const PI200FlowContent = ({ tableAddress }: PI200FlowContentProps) => {
  const { url, ip } = useXmlResource();
  const { user } = useAuth();
  const { units, getFirstValidUrl } = useUnit();
  const filteredUnit = units.filter(unit =>
    getFirstValidUrl(unit).includes(ip),
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const unit = filteredUnit[0];

  const { setPI200TableData, setNotAuth } = useRefrigLayout();

  const {
    data: networkTableData,
    error,
    isLoading,
  } = useSWR(
    [url, unit, tableAddress, XML_REFRIG_LAYOUT_TAB_INDEX.PI200, user],
    fetchLayoutListByUnit,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateIfStale: true,

      refreshInterval: 15000,
    },
  );

  React.useEffect(() => {
    const listItems: ConfigurationListItem[] = getArray(
      networkTableData?.items?.l,
    );
    if (
      listItems.length === 1 &&
      +listItems[0].ef === 0 &&
      parseInt(listItems[0]?.pnum, 10) === 0
    ) {
      setNotAuth(listItems[0]);
    }
    if (listItems.length > 1) {
      const pi200TableContentList: PI200TableContent[] = processPI200TableData(
        networkTableData as any,
      );
      setPI200TableData({ piTableContent: pi200TableContentList });
    }
  }, [networkTableData]);

  if (isXmlError(error)) {
    Notification.error({
      message: t('t17'),
      description: t('t3349'),
      duration: 3,
      theme,
      testId: 'p1200-flowContent-notification',
    });
  }
  const revalidate = async () => {
    const result = await fetchLayoutListByUnit([
      url,
      unit,
      tableAddress,
      XML_REFRIG_LAYOUT_TAB_INDEX.PI200,
      user,
    ]);
    if (result?.items?.l) {
      const pi200TableContentList: PI200TableContent[] = processPI200TableData(
        result as any,
      );
      setPI200TableData({ piTableContent: pi200TableContentList });
    }
  };

  const saveSelectedRow = async (listItem: ConfigurationListItem) => {
    const response = await withErrorHandled(
      t,
      theme,
      writeConfigurationListItem,
    )(
      url,
      user,
      null,
      `${tableAddress}-${XML_REFRIG_LAYOUT_TAB_INDEX.PI200}`,
      null,
      '0',
      '0',
      listItem,
      listItem.fVal,
      listItem.iVal,
      '',
    );
    if (response?.operation === REFRIG_SUCCESS_RESPONSE) {
      await revalidate();
    }
  };

  const handleOnSelect = React.useCallback(
    async (selectedListItem: ConfigurationListItem) => {
      await saveSelectedRow(selectedListItem);
    },
    [],
  );

  return (
    <PI200FlowPage
      tableDataLoading={isLoading}
      handleOnSelect={handleOnSelect}
    />
  );
};
