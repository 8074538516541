import React from 'react';
import {
  SelectInput,
  SelectInputOption,
  Spinner,
  SpinnerSize,
  Table,
  TextInput,
  Notification,
  NumberInput,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { EmptyState } from '@danfoss/etui-sm';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  DEVICE_TYPE,
  DiscoveryTableData,
  HvacTableData,
} from '../../../../RefrigLayoutModal/types';
import {
  getHvacModelListName,
  getTypeDescription,
  hasSpecialCharacters,
} from '../../../../RefrigLayoutModal/utils';
import { useRefrigLayout } from '../../../../RefrigLayoutModal/context';
import { LayoutDropDownMenu } from '../../../..';
import { isAddressEditable, isModelEditable } from '../../utils';
import { validateAddress } from '../../actions';
import { HVACFinalAddDevice } from '.';

export interface HVACFinalTableProps {
  tableDataLoading: boolean;
  handleOnCopyOk: (copyAmount: number, selectedRowIndex: number) => void;
  handleDeleteSelectedRow: (selectedRowIndex: number) => void;
  handleOnAddRack: () => void;
}
export const HVACFinalTable = ({
  tableDataLoading,
  handleOnCopyOk,
  handleDeleteSelectedRow,
  handleOnAddRack,
}: HVACFinalTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    hvacDeviceTypeModelList,
    hvacFinalTableData,
    deviceConstraintsData,
    setHvacFinalTableData,
  } = useRefrigLayout();

  const [deviceNames, setDeviceNames] = React.useState<string[]>([]);
  const [deviceAddress, setDeviceAddress] = React.useState<string[]>([]);
  const [isTableValuesUpdated, setIsTableValuesUpdated] =
    React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<HvacTableData[]>([]);

  React.useEffect(() => {
    if (hvacFinalTableData?.length) {
      processTableValues();
    }
  }, [hvacFinalTableData]);

  React.useEffect(() => {
    if (isTableValuesUpdated) {
      getHvacTableData();
    }
  }, [isTableValuesUpdated]);

  const processTableValues = () => {
    const hvacDeviceNames: string[] = [];
    const hvacDeviceAddress: string[] = [];
    hvacFinalTableData?.forEach(device => {
      device.listname = getHvacModelListName(
        device,
        hvacDeviceTypeModelList?.modelList,
      );
      hvacDeviceNames.push(device?.name);
      hvacDeviceAddress.push(device?.address);
    });
    setDeviceNames(hvacDeviceNames);
    setDeviceAddress(hvacDeviceAddress);
    setIsTableValuesUpdated(true);
  };

  const handleOnDeviceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;
    setDeviceNameValue(selectedRowIndex, value);
    setIsTableValuesUpdated(true);
  };

  const handleOnDeviceAddressChange = (
    address: string,
    selectedRowIndex: number,
  ) => {
    const evaluatedAddress =
      address === ''
        ? '0'
        : isOnlyNumber(address)
        ? address
        : deviceAddress[selectedRowIndex];
    setDeviceAddressValue(selectedRowIndex, evaluatedAddress);
    setIsTableValuesUpdated(true);
  };

  const setDeviceAddressValue = (
    selectedRowIndex: number,
    newAddress: string,
  ) => {
    const address = deviceAddress.slice();
    address[selectedRowIndex] = newAddress;
    setDeviceAddress(address);
  };

  const setDeviceNameValue = (selectedRowIndex: number, value: string) => {
    const names = deviceNames.slice();
    names[selectedRowIndex] = value;
    setDeviceNames(names);
  };

  const saveName = (
    event: React.FocusEvent<HTMLInputElement>,
    selectedRowIndex: number,
  ) => {
    const { value } = event.target;

    const selectedHvacDevice: DiscoveryTableData =
      hvacFinalTableData[selectedRowIndex];

    if (selectedHvacDevice?.name !== value) {
      hvacFinalTableData?.forEach(device => {
        if (device?.deviceId === selectedHvacDevice?.deviceId) {
          device.name = value;
        }
      });
      setDeviceNameValue(selectedRowIndex, value);
      setHvacFinalTableData(hvacFinalTableData);
      setIsTableValuesUpdated(true);
    }
  };

  const validateAndSaveAddress = (value: string, selectedRowIndex: number) => {
    const selectedHvacDevice: DiscoveryTableData =
      hvacFinalTableData[selectedRowIndex];

    if (selectedHvacDevice?.address !== value) {
      const invalidMessage: string = validateAddress(
        value,
        selectedHvacDevice?.deviceId,
        deviceConstraintsData,
        hvacFinalTableData,
      );

      let address: string = '';
      if (invalidMessage) {
        Notification.error({
          message: t('t17'),
          description:
            invalidMessage === 't3520'
              ? t(invalidMessage, { maxAddr: deviceConstraintsData.max_naddr })
              : t(invalidMessage),
          duration: 3,
          theme,
          testId: 'hvacFinal-invalidMessage-notification',
        });
        address = '0';
      } else {
        address =
          value.trim() !== '' &&
          !hasSpecialCharacters(value) &&
          isOnlyNumber(value)
            ? value
            : deviceAddress[selectedRowIndex];
      }

      hvacFinalTableData?.forEach(device => {
        if (device?.deviceId === selectedHvacDevice?.deviceId) {
          device.address = address;
        }
      });
      setDeviceAddressValue(selectedRowIndex, address);
      setHvacFinalTableData(hvacFinalTableData);
      setIsTableValuesUpdated(true);
    }
  };
  const isOnlyNumber = (value: string) => {
    const onlyNumberRegex = /^[0-9]+$/;
    return onlyNumberRegex.test(value);
  };

  const handleOnModelListNameChange = (
    option: SelectInputOption,
    selectedRowIndex: number,
  ) => {
    const { label } = option;

    const selectedHvacDevice: DiscoveryTableData =
      hvacFinalTableData[selectedRowIndex];

    if (selectedHvacDevice?.listname !== label) {
      const selectedModelListNameIndex: number =
        hvacDeviceTypeModelList.modelList.findIndex(model => model._ === label);
      const modelListNames: string[] = label.split(' ');
      hvacFinalTableData?.forEach(device => {
        if (device?.deviceId === selectedHvacDevice?.deviceId) {
          if (selectedModelListNameIndex < deviceConstraintsData?.gen_ofs_ah) {
            device.deviceType = DEVICE_TYPE.HVAC;
            device.address = '-';
            device.version = '';
            device.device = '';
          } else {
            if (device.deviceType !== DEVICE_TYPE.GEN_HVAC) {
              device.address = '0';
            }
            device.deviceType = DEVICE_TYPE.GEN_HVAC;
          }
          device.type = getTypeDescription(device.deviceType, t);
          device.file = '';
          device.version = '';
          const modelValue = modelListNames[0];
          device.model = modelValue;
          device.listname = label;
          setDeviceAddressValue(selectedRowIndex, device.address);
        }
      });
      setHvacFinalTableData(hvacFinalTableData);
      setIsTableValuesUpdated(true);
    }
  };

  const handleOnAddDevice = () => {
    if (hvacFinalTableData?.length) {
      handleOnCopyOk(1, 0);
    } else {
      handleOnAddRack();
    }
  };

  const modelListNameOptions: SelectInputOption[] =
    hvacDeviceTypeModelList?.modelList?.map(value => ({
      value: value._,
      label: value._,
    }));
  const getHvacTableData = () => {
    const hvacTableData: HvacTableData[] = [];
    hvacFinalTableData?.forEach((device, index) => {
      hvacTableData.push({
        name: (
          <Div
            id={`name-div-${index}`}
            testId={`name-div-${index}`}
            width="50%"
          >
            <TextInput
              id={`name-text-${index}`}
              value={deviceNames[index]}
              accept={device?.deviceId?.toString()}
              maxLength={+deviceConstraintsData?.max_namelen}
              type="text"
              aria-label=""
              width="100%"
              height="auto"
              onChange={e => {
                handleOnDeviceNameChange(e, index);
              }}
              onBlur={event => {
                saveName(event, index);
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
            />
          </Div>
        ),
        address: (
          <Div testId="address-div" width="45%">
            <NumberInput
              id={`address-${index}`}
              testId={`hvacAddress-${index ?? ''}-numberInput`}
              accept={device?.deviceId.toString()}
              type="text"
              maxLength={deviceConstraintsData?.max_naddr?.length}
              isDisabled={isAddressEditable(
                hvacDeviceTypeModelList,
                device,
                deviceConstraintsData?.gen_ofs_ah,
              )}
              aria-label=""
              width="100%"
              height="auto"
              onChange={address => {
                handleOnDeviceAddressChange(address, index);
              }}
              onBlur={event => {
                validateAndSaveAddress(event.target.value, index);
              }}
              onKeyDown={event => {
                // Prevent letters from being rendered in the input field
                if (event.keyCode > 64 && event.keyCode < 91) {
                  event.preventDefault();
                }
              }}
              mb="8px"
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
              value={deviceAddress[index]}
            />
          </Div>
        ),
        model: (
          <Div testId="model-div" width="60%" height="90%">
            <SelectInput
              value={{ label: device?.listname, value: '' }}
              options={modelListNameOptions}
              disabled={isModelEditable(device)}
              onChange={(event: SelectInputOption) => {
                handleOnModelListNameChange(event, index);
              }}
              size="small"
              name=""
              searchable={true}
            />
          </Div>
        ),
        operation: (
          <Div testId="copy-delete-div" ml="50%">
            <LayoutDropDownMenu
              rowIndex={index}
              handleOnCopyOk={handleOnCopyOk}
              handleDeleteSelectedRow={handleDeleteSelectedRow}
            />
          </Div>
        ),
        listname: '',
      });
    });
    setTableData(hvacTableData);
    setIsTableValuesUpdated(false);
  };

  const columns = [
    {
      title: t('t76'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: t('t57'),
      dataIndex: 'address',
      key: 'address',
      width: '20%',
    },
    {
      title: t('t355'),
      dataIndex: 'model',
      key: 'model',
      width: '40%',
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      width: '10%',
    },
  ];

  const emptyAddressValues = ['', '-', null];
  const isAddressEmpty = ({ address }) => emptyAddressValues.includes(address);
  const deviceWithoutAddressCount = devices =>
    devices?.filter(isAddressEmpty).length;

  const devicesWithoutAddress = deviceWithoutAddressCount(hvacFinalTableData);
  const maxAllowedDeviceCount =
    deviceConstraintsData?.max_ah - devicesWithoutAddress || 0;
  const hvacDeviceAvailabilityToAdd =
    hvacFinalTableData?.length <= maxAllowedDeviceCount;

  return tableDataLoading ? (
    <Div testId="hvac-flow-table-spinner-div" style={{ marginTop: '200px' }}>
      <Spinner size={SpinnerSize.small} />
    </Div>
  ) : (
    <>
      {!hvacFinalTableData.length && (
        <Div testId="hvac-flow-table-empty-div" style={{ marginTop: '200px' }}>
          <EmptyState title={t('t3351')} />
        </Div>
      )}
      {hvacFinalTableData.length > 0 && (
        <Div testId="hvac-flow-table-content-div" style={{ marginTop: '20px' }}>
          <Table
            testId="hvac-final-table"
            rowKey="hvac-table-content"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            emptyPlaceholder={null}
            styles={{
              td: { p: `${theme.spacing.sm}px` },
              root: { overflow: 'visible' },
            }}
          />
        </Div>
      )}
      <Div
        testId="hvac-add-device-div"
        style={{
          width: '100%',
          display: 'inline-block',
          marginTop: '20px', // Adjust spacing as needed
        }}
      >
        <HVACFinalAddDevice
          handleOnAddDevice={handleOnAddDevice}
          hvacDeviceAvailabilityToAdd={hvacDeviceAvailabilityToAdd}
        />
      </Div>
    </>
  );
};
