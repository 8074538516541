import { Col, Modal, Row } from '@danfoss/etui-core';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { DownloadInProgressModalProps } from '../types';

export function DownloadInProgressModal({
  onClose,
  isOpen,
  textToDisplay = '',
}: DownloadInProgressModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const handleClose = e => {
    onClose(e);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
      testId="download-progress-modal"
      header={
        <Modal.Header
          testId="download-progress-modal-header"
          title={t('t2269')}
          showCloseButton={false}
        />
      }
      styles={{
        contentContainer: {
          width: '500px',
          height: '200px',
          fontSize: theme.typography.fontSizeLarge,
        },
      }}
    >
      <Row>
        <Col xs={12}>
          <Div>{textToDisplay}</Div>
        </Col>
      </Row>
    </Modal>
  );
}
