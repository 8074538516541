import * as React from 'react';
import {
  getUnitUrl,
  fetchLayoutListByUnitWithGroup,
  ConfigurationListItem,
} from '@danfoss/etui-sm-xml';
import { useAuth } from '@danfoss/etui-sm/';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Notification, Spinner, SpinnerSize } from '@danfoss/etui-core';
import { getArray, withErrorHandled } from '../../../../../Configuration/utils';
import { useSaveWithRevalidate } from '../../../../../Configuration/components/ConfigurationItemContentList/hooks/useSaveWithRevalidate';
import { useRefrigCopy } from '../../context';
import { writeCopyConfigurationListItem } from '../../actions/copy-settings-actions';
import { CopyItemSettingsVirtualizedList as VirtualizedList } from './CopyItemSettingsVirtualizedList';

interface CopyItemSettingsContentListProps {
  tableAddress: string;
  index?: string;
  group?: string;
}

function CopyItemSettingsContentList({
  tableAddress,
  index = '2',
  group,
}: CopyItemSettingsContentListProps) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    unit,
    selectedDeviceBpidx,
    currentUnitUrl: xmlBackendURL,
  } = useRefrigCopy();
  const menuId = tableAddress;
  const COMBO = '12';
  const NODE_TYPE = '16';
  const [listData, setListData] = React.useState<ConfigurationListItem[]>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      try {
        const res = await fetchLayoutListByUnitWithGroup([
          getUnitUrl(xmlBackendURL, unit),
          unit,
          tableAddress,
          index,
          user,
          group,
          COMBO,
          NODE_TYPE,
          selectedDeviceBpidx,
        ]);
        setListData(getArray(res?.items?.l));
        setIsLoading(false);
      } catch (error) {
        Notification.error({
          message: t('t17'),
          description: t('t3453'),
          duration: 3,
          theme,
          testId: 'copyItem-settings-notification',
        });
      }
    })();
  }, []);

  const handleSave = React.useCallback(
    async (listItem, { value, ival, fval = null } = {}) => {
      const response = await withErrorHandled(
        t,
        theme,
        writeCopyConfigurationListItem,
      )(
        getUnitUrl(xmlBackendURL, unit),
        user,
        null,
        menuId,
        null,
        group,
        0,
        listItem,
        value,
        ival,
        fval,
        selectedDeviceBpidx,
      );
      return response;
    },

    [unit, user, xmlBackendURL],
  );

  const revalidate = async () => {
    const res = await fetchLayoutListByUnitWithGroup([
      getUnitUrl(xmlBackendURL, unit),
      unit,
      tableAddress,
      index,
      user,
      group,
      COMBO,
      NODE_TYPE,
      selectedDeviceBpidx,
    ]);
    setListData(getArray(res?.items?.l));
  };

  const { execute: executeOnHandleSave } = useSaveWithRevalidate(
    handleSave,
    revalidate,
    1000,
    false,
  );
  return isLoading ? (
    <Spinner size={SpinnerSize.small} />
  ) : (
    <VirtualizedList
      list={listData}
      tabIsEditable={true}
      onSave={executeOnHandleSave}
      group={group}
    />
  );
}

export { CopyItemSettingsContentList };
