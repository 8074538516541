import React from 'react';
import { Alert, icons, SelectInputOption } from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { LayoutFlowTitle } from '../../../RefrigLayoutModal/components/LayoutContent/LayoutFlowTitle';
import { SourceGraphTableContent } from '../../types/RefrigCopy.types';
import { SourceGraphFlowTable } from './SourceGraphFlowTable';

export interface SourceGraphFlowPageProps {
  handleOnLogChange: (
    selectedOption: SelectInputOption,
    content: SourceGraphTableContent,
    selectedRowIndex: number,
  ) => void;
  handleOnHaccpToggle: (
    isChecked: boolean,
    content: SourceGraphTableContent,
    selectedRowIndex: number,
  ) => void;
}

export const SourceGraphFlowPage = ({
  handleOnLogChange,
  handleOnHaccpToggle,
}: SourceGraphFlowPageProps) => {
  const { t } = useTranslation();
  return (
    <Div testId="source-graph-page-div" mt="10px">
      <Alert testId="warning-Alert" type="warning" message={t('t3397')} />
      <LayoutFlowTitle
        title={t('t3398')}
        subTitle={t('t3399')}
        helpText={t('t3400')}
        glyph={icons.GRAPH}
      />
      <SourceGraphFlowTable
        handleOnLogChange={handleOnLogChange}
        handleOnHaccpToggle={handleOnHaccpToggle}
      />
    </Div>
  );
};
