import { ConfigurationTabItem } from '@danfoss/etui-sm-xml';

function findMenuItemByMenuId(
  data: { [key: string]: ConfigurationTabItem[] },
  targetId: string,
): ConfigurationTabItem | null {
  return (
    Object.values(data)
      .flat()
      .find(({ id }) => id === targetId) || null
  );
}

export { findMenuItemByMenuId };
