import React from 'react';
import {
  ButtonProps,
  Icon,
  icons,
  Modal,
  Spinner,
  SpinnerSize,
  Label,
  Notification,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { useAuth, useXmlResource } from '@danfoss/etui-sm';
import { isXmlError, Unit } from '@danfoss/etui-sm-xml';
import { delay } from '../../../../../../Configuration';
import {
  ConfigureDeviceResponse,
  ConfigureDeviceStatusResponse,
  DeviceTypeModelList,
  DiscoveryTableData,
} from '../../../../RefrigLayoutModal/types';
import { getConfigStatus, startConfigHvacDevice } from '../../actions';

export interface HVACLayourFinishModalProps {
  isOpen: boolean;
  onClose: () => void;
  hvacFinalTableData: DiscoveryTableData[];
  hvacDeviceTypeModelList: DeviceTypeModelList;
  handleOnFinish: () => void;
  unit: Unit;
}

export const HVACLayoutFinishModal = ({
  isOpen,
  onClose,
  hvacFinalTableData,
  hvacDeviceTypeModelList,
  handleOnFinish,
  unit,
}: HVACLayourFinishModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { url: xmlBackendURL } = useXmlResource();
  const { user } = useAuth();
  const [closeOnOverLayClick] = React.useState<boolean>(false);
  const [finishStatus, setFinishStatus] = React.useState<string>(t('t798'));
  const [isFinished, setIsFinished] = React.useState<boolean>(false);
  const [configResponse, setConfigResponse] =
    React.useState<ConfigureDeviceResponse>();

  const checkConfigStatus = async () => {
    try {
      const configStatus: ConfigureDeviceStatusResponse = await getConfigStatus(
        [xmlBackendURL, user, unit],
      );

      if (
        +configStatus?.error !== 0 ||
        +configStatus?.error2 !== 0 ||
        +configStatus?.progress > 100
      ) {
        onClose();
        Notification.error({
          message: t('t17'),
          description: configStatus?.error_msg || t('t3376'),
          duration: 3,
          theme,
          testId: 'hvacFinal-finish-notification',
        });
      }
      if (
        +configStatus.error !== 0 ||
        +configStatus.error2 !== 0 ||
        +configStatus.progress < 100
      ) {
        await delay(10000);
        await checkConfigStatus();
      }
      if (+configStatus?.progress === 100) {
        setFinishStatus(t('t3377'));
        setIsFinished(true);
      }
    } catch (e) {
      onClose();
      if (isXmlError(e)) {
        Notification.error({
          message: t('t17'),
          description: e.displayError(t),
          duration: 3,
          theme,
          testId: 'hvacFinal-displayError-notification',
        });
      }
    }
  };

  const startConfigDevice = async () => {
    try {
      const configureHvacDeviceResponse: ConfigureDeviceResponse =
        await startConfigHvacDevice([
          xmlBackendURL,
          user,
          hvacFinalTableData,
          hvacDeviceTypeModelList,
          unit,
        ]);
      setConfigResponse(configureHvacDeviceResponse);
    } catch (e) {
      onClose();
      if (isXmlError(e)) {
        Notification.error({
          message: t('t17'),
          description: e.displayError(t),
          duration: 3,
          theme,
          testId: 'hvacFinal-xmlError-notification',
        });
      }
    }
  };

  React.useEffect(() => {
    const startConfig = async () => {
      await startConfigDevice();
    };
    if (!isFinished) {
      startConfig();
    }
  }, []);

  React.useEffect(() => {
    const checkStatus = async () => {
      await checkConfigStatus();
    };
    if (configResponse && configResponse.received === '1') {
      checkStatus();
    }
  }, [configResponse]);

  const handleCloseClick = () => {
    handleOnFinish();
    onClose();
  };

  const renderButtons = (): Partial<ButtonProps>[] => {
    const closeButton: Partial<ButtonProps> = {
      children: t('t108'),
      block: true,
      onClick: handleCloseClick,
      variant: 'primary',
      testId: 'hvac-close-button',
      disabled: !isFinished,
    };
    return [closeButton];
  };
  return (
    <Modal
      styles={{
        contentContainer: {
          minWidth: [100, 400],
          minHeight: [100, 100],
        },
      }}
      testId="hvac-finish-modal"
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={closeOnOverLayClick}
      actionButtons={renderButtons()}
      header={
        <Modal.Header
          testId="hvac-finish-modal-header"
          title={t('t3378')}
          showCloseButton={false}
        />
      }
    >
      {!isFinished ? (
        <>
          <Div
            testId="wizard-spinner-div"
            style={{ marginTop: '5px', marginLeft: '20px' }}
          >
            <Spinner
              size={SpinnerSize.small}
              styles={{ root: { mb: '10px' } }}
            />
          </Div>
          <Div
            testId="wizard-inprogress-label-div"
            style={{ textAlign: 'center' }}
          >
            <Label testId="wizard-inprogress-label">{finishStatus}</Label>
          </Div>
        </>
      ) : (
        <>
          <Div
            testId="wizard-close-icon-div"
            style={{ marginTop: '5px', marginLeft: '152px' }}
          >
            <Icon
              glyph={icons.CHECK_CIRCLE}
              styles={{
                root: {
                  color: theme.palette.success.main,
                },
              }}
              size={64}
            />
          </Div>
          <Div
            testId="wizard-finished-label-div"
            style={{ marginTop: '10px', marginLeft: '55px' }}
          >
            <Label testId="wizard-finished-label">{finishStatus}</Label>
          </Div>
        </>
      )}
    </Modal>
  );
};
